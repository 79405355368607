import React from 'react';
import { Box } from '@mui/material';
import { useTourFormContext } from '../../../context/tourFormContext';

const ConfirmModalContent = () => {
  const { tourData } = useTourFormContext();

  const label = (label: string) => (
    <Box sx={{ mb: 0.5, color: '#64a8c8', fontWeight: '500', fontSize: '0.9rem' }}>{label}</Box>
  );
  const value = (value: string | number) => (
    <Box sx={{ color: 'grey', fontWeight: '400', fontSize: '0.9rem' }}>{value}</Box>
  );

  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        {label('Nom de la tournée')}
        {value(tourData.name)}
      </Box>
      <Box sx={{ mb: 3 }}>
        {label('Nb de devices sélectionnés')}
        {value(tourData.devicesStep?.length as number)}
      </Box>
      <Box sx={{ mb: 3 }}>
        {label('Releveur sélectionné')}
        {value(tourData.readerStep?.label as string)}
      </Box>
    </Box>
  );
};

export default ConfirmModalContent;

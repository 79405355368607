import React from 'react';
import { Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import { useGetAgents, useGetCompanies, useGetLicences } from '../../../hooks/datarefs';
import { licencesCount } from './licences.utils';
import { Link } from 'react-router-dom';

export default function LicencesStats() {
  const { data: companies } = useGetCompanies();
  const { data: agents } = useGetAgents();
  const { data: licences } = useGetLicences();

  // count actives licences in agents' list
  let count_actives_licences = 0;
  agents &&
    agents.map((agent) => {
      if (agent.State === 1) {
        count_actives_licences += 1;
      }
      return agent;
    });

  // count actives licences not used in licences' list
  let count_actives_not_used_licences = 0;
  licences &&
    licences.map((licence) => {
      const date_last = licence.date_last;
      // if date_last different from 'null'
      if (date_last) {
        if (licencesCount(date_last) > 180) {
          count_actives_not_used_licences += 1;
        }
      }
      return licence;
    });

  return (
    <>
      <Card sx={{ textAlign: 'center', height: '300px' }}>
        <CardContent>
          <Typography variant="h5" component="div">
            Informations licences
          </Typography>
          <Grid container spacing={1} sx={{ mt: 2 }}>
            <Grid item xs={12}>
              Nombre de clients : <strong>{companies && companies.length}</strong>
            </Grid>
            <Grid item xs={12}>
              {' '}
              Licences actives :{' '}
              <strong>
                {count_actives_licences} / {agents && agents.length}
              </strong>
            </Grid>
            <Grid item xs={12}>
              Licences actives non utilisées depuis 6 mois :{' '}
              <strong>{count_actives_not_used_licences}</strong>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            variant="outlined"
            sx={{ margin: 'auto' }}
            component={Link}
            to="/bconnect/dashboard/licences"
          >
            Voir détails
          </Button>
        </CardActions>
      </Card>
    </>
  );
}

import {
  AppPaper,
  BirdzDialog,
  BirdzTitle,
  ListField,
  ListPage,
  useDialog
} from '@applications-terrains/birdz-react-library';
import { Grid, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { useFetchContext } from '../../../../contexts/fetchContext';
import { Support } from '../Supports/SupportsList';
import { Delete, Edit } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import SupportSourceSelector from '../Supports/SupportSourceSelector';
import SupportForm from '../Supports/SupportForm';
import axios from 'axios';
import ModalWithLoader, { RequestStatus } from '../../../Tools/ModalWithLoader/ModalWithLoader';

export type ReaderId = string | number | null | 'add';

export default function ReadersList() {
  const { id } = useParams();
  const endpoint = `/api/boi/breader/readers/`;
  const { needToFetch, toggleNeedToFetch } = useFetchContext();
  const [readerId, setReaderId] = useState<ReaderId>(null);
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const [newSupportSource, setNewSupportSource] = useState<'users' | 'new'>('new');
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(null);

  const listFields: ListField[] = [
    {
      name: 'name',
      label: 'Nom'
    },
    {
      name: 'phone',
      label: 'Téléphone'
    },
    {
      name: 'email',
      label: 'Email'
    }
  ];

  const actions = [
    {
      name: 'modifier',
      label: 'Modifier le releveur',
      render: (reader: Support) => {
        return (
          <IconButton onClick={() => setReaderId(reader.id)}>
            <Edit fontSize="small" color="action" />
          </IconButton>
        );
      }
    },
    {
      name: 'delete-support',
      label: 'Supprimer le releveur',
      render: (reader: Support) => {
        return (
          <IconButton
            onClick={() => {
              confirmDialog({
                title: 'Supprimer le releveur',
                content: 'Êtes-vous sûr de vouloir supprimer ce releveur ?',
                onValidate: () => {
                  setRequestStatus('pending');
                  axios
                    .delete(`${endpoint}${reader.id}`)
                    .then(() => {
                      setRequestStatus('success');
                    })
                    .catch(() => {
                      setRequestStatus('error');
                    });
                },
                onCancel: () => {
                  closeDialog();
                }
              });
            }}
          >
            <Delete fontSize="small" color="action" />
          </IconButton>
        );
      }
    }
  ];
  const handleCloseModalWithLoader = () => {
    closeDialog();
    setRequestStatus(null);
    toggleNeedToFetch(true);
  };

  return (
    <AppPaper sx={{ mt: 4 }}>
      <Grid justifyContent="space-between" container>
        <Grid item>
          <BirdzTitle>Releveurs</BirdzTitle>
        </Grid>
        <Grid item>
          <SupportSourceSelector
            name="releveur"
            setNewSupportSource={setNewSupportSource}
            newSupportSource={newSupportSource}
            setSupportId={setReaderId}
          />
        </Grid>
      </Grid>
      {!needToFetch && (
        <ListPage
          endpoint={endpoint}
          fields={listFields}
          filters={`&client=${id}`}
          actions={actions}
        />
      )}
      {readerId !== null && (
        <SupportForm
          name="reader"
          supportId={readerId}
          setSupportId={setReaderId}
          newSupportSource={newSupportSource}
        />
      )}
      <BirdzDialog options={dialogOptions} />
      <ModalWithLoader
        openModal={requestStatus === 'pending'}
        onClose={() => handleCloseModalWithLoader()}
        onSuccess={() => handleCloseModalWithLoader()}
        action="Suppression du releveur"
        status={requestStatus}
        setStatus={setRequestStatus}
      />
    </AppPaper>
  );
}

import React, { ReactNode } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useGlobalContext } from '../../../contexts/globalContext';
import './pages.scss';

const EmptyPageWithLoader = ({
  className,
  message,
  hideSpinner,
  height
}: {
  className?: string;
  message?: ReactNode;
  hideSpinner?: boolean;
  height?: number;
}) => {
  const { theme } = useGlobalContext();

  return (
    <Box
      className={`w-100 h-100 d-flex align-items-center justify-content-center ${className ? className : ''}`}
      sx={{
        minHeight: height || 'calc(100vh - 350px)',
        boxSizing: 'border-box'
      }}
    >
      <Box className="d-flex flex-column align-items-center justify-content-center">
        {message && <Box sx={{ mb: 2 }}>{message}</Box>}
        {hideSpinner ? (
          <Box sx={{ height: 50 }}></Box>
        ) : (
          <CircularProgress className={`spinner ${theme}`} />
        )}
      </Box>
    </Box>
  );
};

export default EmptyPageWithLoader;

import React, { useEffect, useState } from 'react';
import {
  BirdzDialog,
  useDialog,
  InputField,
  ListPage
} from '@applications-terrains/birdz-react-library';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import { Box, Button, CircularProgress, Grid, IconButton, Paper } from '@mui/material';
import { BirdzNotif, useNotif } from '@applications-terrains/birdz-react-library';
import { Formik } from 'formik';
import { ExportFormat } from '../ExportFormats/ExportFormatForm';
import Visibility from '@mui/icons-material/Visibility';
import TerminalIcon from '@mui/icons-material/Terminal';
import DeleteIcon from '@mui/icons-material/Delete';
import AssociatedClients from './AssociatedClients';

export type ParamTypes = {
  id: string;
};

export type Erp = {
  id: number;
  name: string;
  description: string;
  version: string;
  clients: number[];
  clients_objects: Array<{ name: string; description: string }>;
  clients_count: number;
  export_formats: number[];
  export_formats_objects: ExportFormat[];
  export_formats_count: number;
  created_at: number;
  updated_at: number;
  active: boolean;
};

const ErpForm = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitInProgress, setSubmitInProgress] = useState<boolean>(false);
  const [erp, setErp] = useState<Partial<Erp>>({});
  const { notif, notifOptions } = useNotif();
  const { id } = useParams<ParamTypes>();
  const navigate = useNavigate();
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      axios.get(`/api/boi/extractor/erps/${id}`).then((response: AxiosResponse<Erp>) => {
        setErp(response.data);
        setIsLoading(false);
      });
    }
  }, [id]);

  const saveErp = (erp: Erp) => {
    setSubmitInProgress(true);
    const method = id ? 'put' : 'post';
    const url = id ? `/api/boi/extractor/erps/${id}` : '/api/boi/extractor/erps/';
    axios[method](url, erp)
      .then(() => {
        notif({
          type: 'success',
          content: "L'erp a bien été enregistré"
        });
        setTimeout(() => {
          navigate('/bconnect/extractor/admin/erps');
        }, 2000);
      })
      .catch(() => {
        notif({
          type: 'error',
          content: "Une erreur est survenue lors de l'enregistrement de l'erp"
        });
      })
      .finally(() => {
        setSubmitInProgress(false);
      });
  };

  return (
    <>
      {isLoading ? (
        <Box textAlign="center">
          <CircularProgress />
          <h3>Chargement en cours...</h3>
        </Box>
      ) : (
        <Paper variant="outlined" square sx={{ p: 2 }}>
          <h3>{id ? "Modifier l'erp" : "Ajouter l'erp"}</h3>

          <Formik
            initialValues={erp}
            enableReinitialize={true}
            onSubmit={async (values: any) => {
              saveErp(values);
            }}
          >
            {(props: any) => {
              const { errors, setSubmitting, handleSubmit } = props;
              return (
                <form
                  id="topForm"
                  noValidate
                  onSubmit={(e) => {
                    e.preventDefault();
                    setSubmitting(true);
                    // Check
                    if (Object.keys(errors).length === 0) {
                      handleSubmit();
                    }
                  }}
                >
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                      <Grid container alignItems="center">
                        <Grid alignItems={'center'} item xs={2}>
                          Nom
                        </Grid>
                        <Grid alignItems={'center'} item xs={4}>
                          <InputField name="name" type="text" size="small" fullWidth />
                        </Grid>
                        <Grid alignItems={'center'} item xs={2} sx={{ pl: 1 }}>
                          Version
                        </Grid>
                        <Grid alignItems={'center'} item xs={4}>
                          <InputField name="version" type="text" size="small" fullWidth />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid alignItems={'center'} item xs={2}>
                      Description
                    </Grid>
                    <Grid alignItems={'center'} item xs={10}>
                      <InputField
                        name="description"
                        type={'textarea'}
                        multiline
                        rows={3}
                        fullWidth
                      />
                    </Grid>
                  </Grid>

                  <AssociatedClients erp={erp} />

                  <h4>Liste des formats associés</h4>
                  <ListPage
                    loadedData={erp?.export_formats_objects || []}
                    fields={[
                      {
                        name: 'name',
                        label: 'Nom'
                      },
                      {
                        name: 'version',
                        label: 'Version'
                      },
                      {
                        name: 'active',
                        label: 'Actif'
                      },
                      {
                        name: 'jobs_count',
                        label: 'Nb jobs'
                      }
                    ]}
                    actions={[
                      {
                        name: 'view-export-format',
                        label: "Voir le format d'export",
                        render: (erp: Erp) => {
                          return (
                            <IconButton
                              component={Link}
                              to={`/bconnect/extractor/admin/export-formats/edit/${erp.id}`}
                            >
                              <Visibility fontSize="small" />
                            </IconButton>
                          );
                        }
                      },
                      {
                        name: 'jobs',
                        label: 'Aller à la liste des jobs',
                        render: (export_formats_objects: ExportFormat) => {
                          return (
                            <IconButton
                              component={Link}
                              to={`/bconnect/extractor/jobs/?erp=${export_formats_objects.erp}&export_format_name=${export_formats_objects.name}`}
                            >
                              <TerminalIcon fontSize="small" />
                            </IconButton>
                          );
                        }
                      },
                      {
                        name: 'delete-export-formats',
                        label: "Supprimer le format d'export",
                        render: (model: any) => {
                          return (
                            <IconButton
                              onClick={() => {
                                confirmDialog({
                                  title: "Supprimer le format d'export",
                                  content: "Êtes-vous sûr de vouloir supprimer ce format d'export?",
                                  onValidate: () => {
                                    axios
                                      .delete(`/api/boi/extractor/export-formats/${model.id}/`)
                                      .then(
                                        () => {
                                          notif({
                                            content:
                                              "Le format d'export a été supprimé avec succès",
                                            type: 'success'
                                          });
                                          setTimeout(() => {
                                            navigate(0);
                                          }, 2000);
                                        },
                                        () => {
                                          notif({
                                            content:
                                              'Une erreur est survenue lors de la suppression',
                                            type: 'error'
                                          });
                                        }
                                      );
                                    closeDialog();
                                  },
                                  onCancel: () => {
                                    closeDialog();
                                  }
                                });
                              }}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          );
                        }
                      }
                    ]}
                    displayResultsNumber={false}
                    displayPaginationOptions={false}
                  />

                  <Box sx={{ textAlign: 'center' }}>
                    <Button
                      type="submit"
                      variant="contained"
                      form="topForm"
                      sx={{ m: 2 }}
                      disabled={submitInProgress}
                    >
                      {submitInProgress && <CircularProgress size={18} thickness={5} />} Enregistrer
                    </Button>

                    <Button
                      className="btn-space"
                      onClick={() => {
                        confirmDialog({
                          title: 'Annuler les saisies',
                          content: 'Souhaitez-vous annuler vos saisies ?',
                          onValidate: () => {
                            closeDialog();
                            navigate(-1);
                          },
                          onCancel: () => {
                            closeDialog();
                          },
                          okButtonLabel: 'Oui',
                          cancelButtonLabel: 'Non'
                        });
                      }}
                    >
                      Annuler
                    </Button>
                  </Box>
                </form>
              );
            }}
          </Formik>
          <BirdzNotif options={notifOptions} />
          <BirdzDialog options={dialogOptions} />
        </Paper>
      )}
    </>
  );
};

export default ErpForm;

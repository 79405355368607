import React, { useEffect, useState } from 'react';
import { BirdzDialog, useDialog, ListPage } from '@applications-terrains/birdz-react-library';
import { Link, useParams } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import { Button, Grid, IconButton } from '@mui/material';
import { BirdzNotif, useNotif } from '@applications-terrains/birdz-react-library';
import Visibility from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Erp, ParamTypes } from './ErpForm';
import Select from 'react-select';

type Client = {
  id: number;
  name: string;
};

const AssociatedClients = ({ erp }: { erp: Partial<Erp> }) => {
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const { notif, notifOptions } = useNotif();
  const { id } = useParams<ParamTypes>();

  const [availableClients, setAvailableClients] = useState([]);
  const [clientToAdd, setClientToAdd] = useState<Client | null>(null);

  useEffect(() => {
    if (id) {
      axios.get(`/api/boi/extractor/clients/?size=999`).then((response: AxiosResponse) => {
        const clients = response.data.results;
        setAvailableClients(clients.filter((client: Client) => !erp.clients?.includes(client.id)));
      });
    }
  }, [id]);

  return (
    <>
      <Grid justifyContent="space-between" container sx={{ mt: 6 }}>
        <Grid item>
          <h4>Liste des clients associés</h4>
        </Grid>
        <Grid item sx={{ mt: 3 }}>
          <div style={{ display: 'flex' }}>
            <Select
              name="clients"
              options={availableClients.map((client: Client) => ({
                label: client.name,
                value: client.id
              }))}
              onChange={(selectedOption) => {
                setClientToAdd({
                  name: selectedOption?.label as string,
                  id: selectedOption?.value as number
                });
              }}
            />
            <Button
              variant="contained"
              sx={{ ml: 2 }}
              disabled={!clientToAdd}
              onClick={() => {
                clientToAdd &&
                  confirmDialog({
                    title: 'Associer un client',
                    content: `Êtes-vous sûr de vouloir associer le client ${clientToAdd.name} ?`,
                    onValidate: () => {
                      const payload = {
                        name: erp.name,
                        description: erp.description,
                        version: erp.version,
                        updated_at: erp.updated_at,
                        clients: [...(erp.clients as []), clientToAdd.id]
                      };
                      axios.put(`/api/boi/extractor/erps/${id}`, payload).then(
                        () => {
                          notif({
                            content: `Le client ${clientToAdd.name} a été associé avec succès`,
                            type: 'success'
                          });
                          document.location.reload();
                          setClientToAdd(null);
                        },
                        () => {
                          notif({
                            content: 'Une erreur est survenue lors de la mise à jour',
                            type: 'error'
                          });
                        }
                      );
                      closeDialog();
                    },
                    onCancel: () => {
                      closeDialog();
                    }
                  });
              }}
            >
              <AddCircleIcon sx={{ mr: 1 }} /> Ajouter un client
            </Button>
          </div>
        </Grid>
      </Grid>
      <ListPage
        loadedData={erp?.clients_objects || []}
        fields={[
          {
            name: 'name',
            label: 'Nom'
          },
          {
            name: 'description',
            label: 'Description'
          }
        ]}
        displayResultsNumber={false}
        displayPaginationOptions={false}
        actions={[
          {
            name: 'view',
            render: (client: any) => {
              return (
                <IconButton
                  component={Link}
                  to={`/bconnect/extractor/admin/clients/edit/${client.id}`}
                >
                  <Visibility fontSize="small" />
                </IconButton>
              );
            }
          },
          {
            name: 'delete-client-association',
            label: "Supprimer l'association avec le client",
            render: (client: { name: string; description: string; id: number | string }) => {
              return (
                <IconButton
                  onClick={() => {
                    confirmDialog({
                      title: "Supprimer l'association avec un client",
                      content: `Êtes-vous sûr de vouloir supprimer l'association avec le client ${client.name} ?`,
                      onValidate: () => {
                        const payload = {
                          ...erp,
                          clients: erp.clients?.filter((clientId) => clientId !== client.id)
                        };
                        axios.put(`/api/boi/extractor/erps/${id}`, payload).then(
                          () => {
                            notif({
                              content: `L'association avec le client ${client.name} a été supprimée avec succès`,
                              type: 'success'
                            });
                            document.location.reload();
                          },
                          () => {
                            notif({
                              content: 'Une erreur est survenue lors de la suppression',
                              type: 'error'
                            });
                          }
                        );
                        closeDialog();
                      },
                      onCancel: () => {
                        closeDialog();
                      }
                    });
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              );
            }
          }
        ]}
      />
      <BirdzDialog options={dialogOptions} />
      <BirdzNotif options={notifOptions} />
    </>
  );
};

export default AssociatedClients;

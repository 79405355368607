import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { authService } from '..';
import { SubcontractorDetail } from './datarefs.types';
import { Agent, Company, Licence } from '../components/BConnect/Types';
import { ModuleFamilyType } from '../components/Extractor/Clients/ClientsForm';

type Subcontractors = {
  [key: string]: any;
};

type CustomFieldsResults = {
  label?: string;
  value?: string;
};

export type ApiServiceErr = any;

export const defaultSize = 999999;

type BasicType = {
  id: number;
  created_at: number;
  updated_at: number;
};

export const useGetSubcontractors = () =>
  useQuery<Subcontractors[], ApiServiceErr>(
    ['subcontractors'],
    async () => {
      const response = await axios.get(
        `/api/boi/subcontractors/subcontractors/?size=${defaultSize}&ordering=name&is_active=true`
      );
      return response.data.results;
    },
    { enabled: authService.canAccessBOI() }
  );

export const useGetSubcontractorsMR = () =>
  useQuery<Subcontractors[], ApiServiceErr>(
    ['subcontractors-mr'],
    async () => {
      const response = await axios.get(
        `/api/boi/subcontractors/subcontractors/?size=${defaultSize}&ordering=name&is_active=true&action_type=MR`
      );
      return response.data.results;
    },
    { enabled: authService.canAccessBOI() }
  );

export const useGetInterventionEvents = () =>
  useQuery<any[], ApiServiceErr>(
    ['intervention-events'],
    async () => {
      const response = await axios.get(
        `/api/boi/params/intervention-events/?size=${defaultSize}&ordering=label`
      );
      return response.data.results;
    },
    { enabled: authService.canAccessBOI() }
  );

export const useGetInterventionStatuses = () =>
  useQuery<any[], ApiServiceErr>(['intervention-statuses'], async () => {
    const endpoint = authService.getEndpoint(
      `/api/boi/params/intervention-statuses/`,
      `/api/boe/params/intervention-statuses/`
    );
    const response = await axios.get(`${endpoint}?size=${defaultSize}&ordering=order`);
    return response.data.results;
  });

export const useGetCaseEvents = () =>
  useQuery<any[], ApiServiceErr>(['case-events'], async () => {
    const response = await axios.get(
      `/api/boi/params/case-events/?size=${defaultSize}&ordering=label`
    );
    return response.data.results;
  });

export const useGetCaseStatuses = () =>
  useQuery<any[], ApiServiceErr>(['case-statuses'], async () => {
    const response = await axios.get(
      `/api/boi/params/case-statuses/?size=${defaultSize}&ordering=order`
    );
    return response.data.results;
  });

export const useGetCaseTypes = () =>
  useQuery<any[], ApiServiceErr>(['case-types'], async () => {
    const response = await axios.get(
      `/api/boi/params/case-types/?size=${defaultSize}&ordering=label`
    );
    return response.data.results;
  });

export const useGetContracts = (endpoint?: string) =>
  useQuery<any[], ApiServiceErr>(['contracts'], async () => {
    const response = await axios.get(endpoint || `/api/boi/params/contracts/?size=${defaultSize}`);
    return response.data.results || response.data;
  });

export const useGetFailureCodes = () =>
  useQuery<any[], ApiServiceErr>(['failure-codes'], async () => {
    const response = await axios.get(
      `/api/boi/params/failure-codes/?size=${defaultSize}&ordering=label`
    );
    return response.data.results;
  });

export const useGetMessagesTypes = () =>
  useQuery<any[], ApiServiceErr>(['messages-types'], async () => {
    const response = await axios.get(
      `/api/boi/administration/messages-type/?size=${defaultSize}&ordering=label&is_active=true`
    );
    return response.data;
  });

export const useGetTechnicians = () =>
  useQuery<any[], ApiServiceErr>(['technicians'], async () => {
    const response = await axios.get(
      `/api/boi/subcontractors/technicians/?size=${defaultSize}&ordering=user__name`
    );
    return response.data.results;
  });

export const useGetGroups = () =>
  useQuery<any[], ApiServiceErr>(['groups'], async () => {
    const endpoint = authService.getEndpoint(
      `/api/boi/administration/groups/`,
      `/api/boe/subcontractors/groups/`
    );
    const response = await axios.get(
      `${endpoint}?size=${defaultSize}&ordering=name&is_active=true`
    );
    return response.data.results;
  });

export const useGetYears = () =>
  useQuery<any[], ApiServiceErr>(['years'], async () => {
    const endpoint = authService.getEndpoint(`/api/boi/params/years/`, `/api/boe/params/years/`);
    const response = await axios.get(`${endpoint}?size=${defaultSize}&ordering=label`);
    return response.data.results;
  });

export const useGetMonths = () =>
  useQuery<any[], ApiServiceErr>(['months'], async () => {
    const endpoint = authService.getEndpoint(`/api/boi/params/months/`, `/api/boe/params/months/`);
    const response = await axios.get(`${endpoint}?size=${defaultSize}&ordering=id`);
    return response.data.results;
  });

export const useGetUsers = (enableFetch = true) =>
  useQuery<any[], ApiServiceErr>(
    ['users'],
    async () => {
      const response = await axios.get(
        `/api/boi/administration/users/?size=${defaultSize}&ordering=name&is_active=true`
      );
      return response.data.results;
    },
    { enabled: authService.canAccessBOI() && enableFetch }
  );

export const useGetSections = () =>
  useQuery<any[], ApiServiceErr>(['sections'], async () => {
    const response = await axios.get(
      `/api/boi/administration/sections/?size=${defaultSize}&ordering=name&is_active=true`
    );
    return response.data.results;
  });

export const useGetMRInterventionTypes = () =>
  useQuery<(BasicType & { code: string; label: string })[], ApiServiceErr>(
    ['intervention_type'],
    async () => {
      const response = await axios.get(
        `/api/boi/params/mr-intervention-type/?size=${defaultSize}&ordering=id`
      );
      return response.data.results;
    }
  );

export const useGetMRDeviceRequests = () =>
  useQuery<(BasicType & { clause_where: string; label: string; type: number })[], ApiServiceErr>(
    ['mr-devices-request'],
    async () => {
      const response = await axios.get(`/api/boi/params/mr-devices-request`);
      return response.data.results;
    },
    { enabled: authService.canAccessBOI() }
  );

export const useGetCrCities = () =>
  useQuery<any[], ApiServiceErr>(
    ['cr-cities'],
    async () => {
      const response = await axios.get(`/api/boi/cases/cr-cases/cities/`);
      return response.data;
    },
    { enabled: authService.canAccessBOI() }
  );

export const useGetCrContracts = () =>
  useQuery<any[], ApiServiceErr>(
    ['cr-contracts'],
    async () => {
      const response = await axios.get(`/api/boi/cases/cr-cases/contracts`);
      return response.data;
    },
    { enabled: authService.canAccessBOI() }
  );

export const useGetSubcontractorDetails = () =>
  useQuery<SubcontractorDetail, ApiServiceErr>(
    ['subcontractors-boe'],
    async () => {
      const response = await axios.get(`/api/boe/subcontractors/subcontractors/details/`);
      return response.data;
    },
    { enabled: authService.canAccessBOE() }
  );

export const useGetModuleFamilies = () =>
  useQuery<Array<ModuleFamilyType>, ApiServiceErr>(
    ['module-families'],
    async () => {
      const response = await axios.get(`/api/boi/extractor/module-families/`);
      return response.data;
    },
    { enabled: authService.canAccessBOI() }
  );

export const useGetAnomalyProfiles = () =>
  useQuery<any[], ApiServiceErr>(
    ['anomaly-profiles'],
    async () => {
      const response = await axios.get(`/api/boi/extractor/anomaly-profiles/`);
      return response.data;
    },
    { enabled: authService.canAccessBOI() }
  );

export const useGetErps = () =>
  useQuery<any[], ApiServiceErr>(
    ['erps'],
    async () => {
      const response = await axios.get(`/api/boi/extractor/erps/`);
      return response.data.results;
    },
    { enabled: authService.canAccessBOI() }
  );

export const useGetAnomalyRules = () =>
  useQuery<any[], ApiServiceErr>(
    ['anomaly-rules'],
    async () => {
      const response = await axios.get(`/api/boi/params/anomaly-rules/`);
      return response.data;
    },
    { enabled: authService.canAccessBOI() }
  );

export const useGetClients = () =>
  useQuery<any[], ApiServiceErr>(
    ['clients'],
    async () => {
      const response = await axios.get(`/api/boi/extractor/clients/?size=999`);
      return response.data.results;
    },
    { enabled: authService.canAccessBOI() }
  );

export const useGetExportFormats = () =>
  useQuery<any[], ApiServiceErr>(
    ['export-formats'],
    async () => {
      const response = await axios.get(`/api/boi/extractor/export-formats/?size=99999`);
      return response.data.results;
    },
    { enabled: authService.canAccessBOI() }
  );

export const useGetExtractorFields = () =>
  useQuery<Array<{ name: string; type: string; id: number; source: string }>, ApiServiceErr>(
    ['extractor-fields'],
    async () => {
      const response = await axios.get(` /api/boi/extractor/fields/`);
      return response.data;
    },
    { enabled: authService.canAccessBOI() }
  );

export const useGetDataMapping = () => {
  return useQuery<any[], ApiServiceErr>(
    ['data-mapping'],
    async () => {
      const response = await axios.get(`/api/boi/extractor/data-mapping/`);
      return response.data;
    },
    { enabled: authService.canAccessBOI() }
  );
};
export const useGetInterventionTypes = () =>
  useQuery<any[], ApiServiceErr>(
    ['intervention-types'],
    async () => {
      const response = await axios.get(`/api/boi/extractor/intervention-types/`);
      return response.data;
    },
    { enabled: authService.canAccessBOI() }
  );

export const useGetBConnectInterventionsEvents = () =>
  useQuery<any[], ApiServiceErr>(['interventions-events'], async () => {
    const response = await axios.get('/api/boi/params/bconnect-interventions-events/?size=100');
    const events = response.data.results;

    const formatEvents = events.map((event: any) => {
      return { value: event.label, label: event.label };
    });

    const eventsListSorted = formatEvents.sort((a: any, b: any) => a.label.localeCompare(b.label));
    return eventsListSorted;
  });

export const formatToSelectOptions = (
  data: any[] | undefined,
  customFieldsResults?: CustomFieldsResults
) => {
  return data?.map((item: any) => ({
    value: item[customFieldsResults?.value || 'id'],
    label: item[customFieldsResults?.label || 'label']
  }));
};

//bconnect
export const useGetCompanies = () =>
  useQuery<Company[], ApiServiceErr>(['companies'], async () => {
    const response = await axios.get(
      `/api/bconnect/webapp/administrator/companies?ordering=Id__Name`
    );
    return response.data.results;
  });

export const useGetAgents = () =>
  useQuery<Agent[], ApiServiceErr>(['agents'], async () => {
    const response = await axios.get(
      `/api/bconnect/webapp/customer/agents/?ordering=Id__Name&size=999999`
    );
    return response.data.results;
  });

export const useGetBConnectAgents = () =>
  useQuery<{ name: string }[], ApiServiceErr>(['agents'], async () => {
    const response = await axios.get(`/api/boi/extractor/agents/?size=99999`);
    return response.data.results;
  });

export const useGetLicences = () =>
  useQuery<Licence[], ApiServiceErr>(['licences'], async () => {
    const response = await axios.get('/api/bconnect/webapp/customer/agents_stats');
    return response.data.results;
  });

//extractor
export const useGetCities = () =>
  useQuery<any[], ApiServiceErr>(
    ['cities'],
    async () => {
      const response = await axios.get(`/api/boi/extractor/interventions/cities/`);
      return response.data;
    },
    { enabled: authService.canAccessBOI() }
  );

export const useGetExtractorInterventionStatuses = () =>
  useQuery<any, ApiServiceErr>(
    ['bconnect-interventions-status'],
    async () => {
      const response = await axios.get(`/api/boi/extractor/interventions-status/`);
      return response.data;
    },
    { enabled: authService.canAccessBOI() }
  );

export const useGetBConnectContracts = () =>
  useQuery<any[], ApiServiceErr>(['bconnect-contracts'], async () => {
    const response = await axios.get(`/api/boi/extractor/interventions/contracts/`);
    return response.data;
  });

//bstock
export const useGetAgentsString = () =>
  useQuery<string[], ApiServiceErr>(['agents'], async () => {
    const response = await axios.get(`/api/bstock/sessions/agents/`);
    return response.data;
  });

//breader
export const useGetBReaderClients = () =>
  useQuery<any[], ApiServiceErr>(
    ['breader-clients'],
    async () => {
      const response = await axios.get(`/api/boi/breader/clients/`);
      return response.data.results;
    },
    { enabled: authService.canAccessBOI() }
  );

import React, { useEffect, useState } from 'react';
import {
  ListPage,
  BirdzDialog,
  useDialog,
  AppPaper,
  Item,
  BirdzTitle
} from '@applications-terrains/birdz-react-library';
import { Link } from 'react-router-dom';
import moment from 'moment';
import * as Yup from 'yup';
import axios from 'axios';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, Grid, IconButton } from '@mui/material';
import {
  BirdzNotif,
  useNotif,
  FormField,
  SearchField
} from '@applications-terrains/birdz-react-library';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import GroupIcon from '@mui/icons-material/Group';
import SettingsIcon from '@mui/icons-material/Settings';
import { formatToSelectOptions, useGetSections } from '../../../hooks/datarefs';

const Groups = (params: any) => {
  const endpoint = '/api/boi/administration/groups/';
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const { data: sections } = useGetSections();
  const { notif, notifOptions } = useNotif();
  const [formFields, setFormFields] = useState<FormField[]>([]);

  useEffect(() => {
    if (!sections) return;

    const formFields: FormField[] = [
      {
        name: 'name',
        type: 'text',
        label: 'Nom',
        validation: Yup.string().required('Vous devez renseigner le nom du groupe')
      },
      {
        name: 'is_active',
        type: 'radio',
        label: 'Etat',
        options: {
          values: [
            { value: true, label: 'Actif' },
            { value: false, label: 'Inactif' }
          ]
        },
        defaultValue: true,
        permissions: ['IS_ACTIVE']
      },
      {
        name: 'sections',
        type: 'select-multiple',
        label: 'Sections',
        options: {
          values: formatToSelectOptions(sections, { label: 'code' })
        },
        validation: Yup.array().min(1, 'Vous devez choisir au moins une section')
      },
      {
        name: 'is_visible_to_subcontractor',
        type: 'checkbox',
        label: 'Visible par les sous-traitants'
      },
      {
        name: 'updated_at',
        type: 'readonly',
        label: 'Dernière modification',
        transform: (value: any) => {
          return (value && moment.unix(value).format('DD/MM/YYYY à HH:mm:ss')) || '';
        }
      }
    ];
    setFormFields(formFields);
  }, [sections]);

  const listFields = [
    { name: 'name', label: 'Nom', orderable: true },
    {
      name: 'is_active',
      label: 'Actif',
      className: 'text-center',
      options: { width: '140px' },
      orderable: true
    },
    {
      name: 'users_list',
      label: 'Liste des utilisateurs',
      className: 'text-center',
      transform: (value: any, item: any) => {
        return (
          <IconButton
            component={Link}
            to={`/boi/administration/users/?groups=${item.id}&groups_name=${item.name}`}
          >
            <GroupIcon fontSize="small" />
          </IconButton>
        );
      },
      options: { width: '170px' }
    },
    {
      name: 'sections_list',
      label: 'Liste des sections',
      className: 'text-center',
      transform: (value: any, item: any) => {
        return (
          <IconButton
            component={Link}
            to={`/boi/administration/sections/?group=${item.id}&group_name=${item.name}`}
          >
            <SettingsIcon fontSize="small" />
          </IconButton>
        );
      },
      options: { width: '150px' }
    },
    {
      name: 'updated_at',
      label: 'Date de modification',
      options: { width: '235px' },
      orderable: true,
      transform: (value: any) => {
        return (value && moment.unix(value).format('DD/MM/YYYY à HH:mm:ss')) || '';
      }
    }
  ];
  const actions = [
    {
      name: 'edit',
      label: 'Modifier le groupe',
      render: (group: any) => {
        return (
          <IconButton component={Link} to={`/boi/administration/groups/edit/${group.id}`}>
            <EditIcon fontSize="small" />
          </IconButton>
        );
      }
    },
    {
      name: 'delete-group',
      label: 'Supprimer le groupe',
      render: (model: any) => {
        return (
          <IconButton
            onClick={() => {
              confirmDialog({
                title: 'Supprimer le groupe',
                content: 'Êtes-vous sûr de vouloir supprimer ce groupe?',
                onValidate: () => {
                  axios.delete(`${endpoint}${model.id}/`).then(
                    () => {
                      notif({
                        content: 'Le groupe a été supprimé avec succès',
                        type: 'success'
                      });
                      document.location.reload();
                    },
                    () => {
                      notif({
                        content: 'Une erreur est survenue lors de la suppression',
                        type: 'error'
                      });
                    }
                  );
                  closeDialog();
                },
                onCancel: () => {
                  closeDialog();
                }
              });
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        );
      }
    }
  ];

  const searchFields: SearchField[] = [
    {
      name: 'name',
      label: 'Nom groupe',
      options: {
        identifier: 'name',
        source: endpoint,
        label: 'name',
        searchIsMulti: false
      }
    }
  ];

  return (
    <AppPaper>
      {params.action === 'list' && (
        <>
          <Grid justifyContent="space-between" container>
            <Grid item>
              <BirdzTitle>Liste des groupes</BirdzTitle>
            </Grid>
            <Grid item>
              <Link to="/boi/administration/groups/add">
                <Button variant="contained" sx={{ mt: 3 }}>
                  <AddCircleIcon sx={{ mr: 1 }} /> Ajouter un groupe
                </Button>
              </Link>
            </Grid>
          </Grid>

          <ListPage
            endpoint={endpoint}
            fields={listFields}
            actions={actions}
            searchFields={searchFields}
            defaultOrder={['name']}
          />
          <BirdzDialog options={dialogOptions} />
        </>
      )}
      {(params.action === 'add' || params.action === 'edit') && (
        <Item
          action={params.action}
          endpoint={endpoint}
          fields={formFields}
          name={`${params.action === 'add' ? 'Ajouter un' : 'Modifier le'} groupe`}
        />
      )}
      <BirdzNotif options={notifOptions} />
    </AppPaper>
  );
};

export default Groups;

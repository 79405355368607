import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  LinearProgress,
  Typography
} from '@mui/material';
import { Link } from 'react-router-dom';

export default function NotationsStats() {
  const [criterias_ids, setCriterias_ids] = useState<number[]>([]);
  const [criterias_names, setCriterias_names] = useState<string[]>([]);
  const [exports, setExports] = useState<any[]>([]);
  const [averageNotes, setAverageNotes] = useState<number[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const NOTE_MAX = 5;

  useEffect(() => {
    setLoading(true);
    if (exports.length > 0) {
      setLoading(false);
    }
  }, [exports]);

  const findAverageNoteByCategory = () => {
    return criterias_ids.map((criteria_id) => {
      const categories = exports.filter((item) => item.NotationCriteriaId === criteria_id);
      const notes = categories.map((item) => {
        return item.Value;
      });

      let average = 0;
      for (let i = 0; i < notes.length; i++) {
        average += notes[i];
      }
      average = Number((average / notes.length).toFixed(2));

      return average;
    });
  };

  useEffect(() => {
    const fetchCriterias = axios.get('/api/bconnect/webapp/administrator/notations/criteria');
    const fetchExports = axios.get('/api/bconnect/webapp/administrator/notations/export');

    axios.all([fetchCriterias, fetchExports]).then(
      axios.spread((...responses) => {
        const criterias: any = responses[0].data;

        const criterias_ids = criterias.map((criteria: any) => {
          return criteria.Id;
        });
        setCriterias_ids(criterias_ids);

        const exports: any = responses[1].data.filter((res: any) => {
          return criterias_ids.includes(res.NotationCriteriaId);
        });
        setExports(exports);

        const criterias_names = criterias.map((category: any) => {
          return category.DefaultName;
        });
        setCriterias_names(criterias_names);
      })
    );
  }, []);

  useEffect(() => {
    setAverageNotes(findAverageNoteByCategory());
  }, [criterias_ids]);

  return (
    <>
      <Card sx={{ textAlign: 'center', height: '300px' }}>
        <CardContent>
          <Typography variant="h5" component="div">
            Notations moyennes
          </Typography>
          {isLoading === true && (
            <>
              <Grid container justifyContent="center" alignItems="center" sx={{ mt: 2 }}>
                <Grid item xs={4}>
                  <LinearProgress />
                </Grid>
              </Grid>
            </>
          )}

          {!isLoading && (
            <>
              {averageNotes.length > 0 && criterias_names.length > 0 && (
                <Grid container spacing={1}>
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <strong>Sur {exports.length} notes</strong>
                  </Grid>

                  <Grid item xs={12} sx={{ mt: 2 }}>
                    {criterias_names[0]} :{' '}
                    <strong>
                      {averageNotes[0]} / {NOTE_MAX}
                    </strong>
                  </Grid>
                  <Grid item xs={12}>
                    {criterias_names[1]} :{' '}
                    <strong>
                      {averageNotes[1]} / {NOTE_MAX}
                    </strong>
                  </Grid>
                  <Grid item xs={12}>
                    {criterias_names[2]} :{' '}
                    <strong>
                      {averageNotes[2]} / {NOTE_MAX}
                    </strong>
                  </Grid>
                  <Grid item xs={12}>
                    {criterias_names[3]} :{' '}
                    <strong>
                      {averageNotes[3]} / {NOTE_MAX}
                    </strong>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </CardContent>
        <CardActions>
          <Button
            size="small"
            variant="outlined"
            sx={{ margin: 'auto' }}
            component={Link}
            to="/bconnect/dashboard/notations"
          >
            Voir détails
          </Button>
        </CardActions>
      </Card>
    </>
  );
}

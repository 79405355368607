import React, { useEffect } from 'react';
import {
  ListPage,
  BirdzDialog,
  useDialog,
  AppPaper,
  BirdzTitle,
  FormField,
  Item
} from '@applications-terrains/birdz-react-library';
import { Link } from 'react-router-dom';
import { Message } from '../../../types';
import axios from 'axios';
import { Button, Grid } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useNotif, BirdzNotif } from '@applications-terrains/birdz-react-library';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import AnomalyProfilesRules from './AnomalyProfilesRules';

const AnomalyProfiles = (params: any) => {
  const [refresh, setRefresh] = React.useState(false);
  const endpoint = '/api/boi/extractor/anomaly-profiles/';
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const { notif, notifOptions } = useNotif();

  useEffect(() => {
    refresh && setRefresh(false);
  }, [refresh]);

  const formFields: FormField[] = [
    {
      name: 'name',
      type: 'text',
      label: 'Nom'
    },
    {
      name: 'checked_rules',
      type: 'custom',
      label: 'Règles',
      render: (values: any, onChange: any) => {
        return (
          <AnomalyProfilesRules
            checkedRules={values || []}
            onCheckedRules={(checkedRules) => {
              onChange(checkedRules);
            }}
          />
        );
      }
    }
  ];

  const listFields = [
    {
      name: 'name',
      label: 'Nom'
    },
    {
      name: 'number_of_rules',
      label: 'Nombre de règles'
    }
  ];

  const searchFields = [
    {
      name: 'name',
      label: 'Nom',
      options: {
        identifier: 'name',
        source: endpoint,
        label: 'name',
        searchIsMulti: false
      }
    }
  ];

  const actions = [
    {
      name: 'edit',
      label: "Modifier le profil d'anomalie",
      render: (message: Message) => {
        return (
          <IconButton
            component={Link}
            to={`/bconnect/extractor/admin/anomaly-profiles/edit/${message.id}`}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        );
      }
    },
    {
      name: 'delete-profile',
      label: "Supprimer le profil d'anomalie",
      render: (model: any) => {
        return (
          <IconButton
            onClick={() => {
              confirmDialog({
                title: "Supprimer le profil d'anomalie",
                content: "Êtes-vous sûr de vouloir supprimer ce profil d'anomalie?",
                onValidate: () => {
                  axios.delete(`${endpoint}${model.id}/`).then(
                    () => {
                      setRefresh(true);
                      notif({
                        content: "Le profil d'anomalie a été supprimé avec succès",
                        type: 'success'
                      });
                    },
                    () => {
                      notif({
                        content: 'Une erreur est survenue lors de la suppression',
                        type: 'error'
                      });
                    }
                  );
                  closeDialog();
                },
                onCancel: () => {
                  closeDialog();
                }
              });
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        );
      }
    }
  ];

  return (
    <AppPaper>
      {params.action === 'list' && (
        <>
          <Grid justifyContent="space-between" container>
            <Grid item>
              <BirdzTitle>Liste des profils d'anomalie</BirdzTitle>
            </Grid>
            <Grid item>
              <Link to="/bconnect/extractor/admin/anomaly-profiles/add">
                <Button variant="contained" sx={{ mt: 3 }}>
                  <AddCircleIcon sx={{ mr: 1 }} /> Ajouter un profil d'anomalie
                </Button>
              </Link>
            </Grid>
          </Grid>

          {!refresh && (
            <ListPage
              endpoint={endpoint}
              fields={listFields}
              actions={actions}
              searchFields={searchFields}
            />
          )}
          <BirdzDialog options={dialogOptions} />
        </>
      )}
      {params.action === 'add' && (
        <Item
          action="add"
          endpoint={endpoint}
          fields={formFields}
          name="Ajouter un profil d'anomalie"
        />
      )}
      {params.action === 'edit' && (
        <Item
          action="edit"
          endpoint={endpoint}
          fields={formFields}
          name="Modifier le profil d'anomalie"
        />
      )}
      <BirdzNotif options={notifOptions} />
    </AppPaper>
  );
};

export default AnomalyProfiles;

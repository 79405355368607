import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  LinearProgress,
  MenuItem,
  Modal,
  Pagination,
  Stack,
  TextField,
  Tooltip
} from '@mui/material';
import React, { JSX, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  AppPaper,
  BirdzNotif,
  BirdzTitle,
  useNotif
} from '@applications-terrains/birdz-react-library';
import {
  AgentProps,
  BrandProps,
  CompanyProps,
  ContractProps,
  InterventionProps,
  ModuleFamiliesProps,
  RalProps,
  RawBrandProps,
  ReasonProps,
  SensorModelProps,
  SupportTypeProps
} from '../../Extractor/Interventions/types';
import axios from 'axios';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import moment from 'moment';
import DeviceMap from '../../CR/Diagnostics/DeviceMap';
import { ModuleData } from '../../CR/Diagnostics/CR.types';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DownloadIcon from '@mui/icons-material/Download';
import InterventionStatusEditor from './InterventionStatusEditor';
import NotPendingHandler from './NotPendingHandler';

type InterventionFormProps = {
  readOnly?: boolean;
};

export default function InterventionDetails({ readOnly = true }: InterventionFormProps) {
  const { id } = useParams();
  const { notif, notifOptions } = useNotif();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  const saveImage = (downloadUrl: string, fileName: string): Promise<void> => {
    return new Promise<void>((resolve) => {
      const downloadImage = document.createElement('a');
      document.body.appendChild(downloadImage);
      downloadImage.setAttribute('download', fileName);
      downloadImage.href = downloadUrl;
      downloadImage.click();
      downloadImage.remove();
      resolve();
    });
  };

  const handleDownloadPhotos = async () => {
    for (let i = 0; i < currentPageData.length; i++) {
      const url = currentPageData[i];
      const fileName = `image_${i + 1}.jpg`;
      await saveImage(url, fileName);
      await delay(500);
    }
  };

  const [moduleData, setModuleData] = useState<ModuleData>();

  const [agents, setAgents] = useState<AgentProps[]>([]);
  const [companies, setCompanies] = useState<CompanyProps[]>();
  const [contracts, setContracts] = useState<ContractProps[]>([]);
  const [moduleFamilies, setModuleFamilies] = useState<ModuleFamiliesProps[]>([]);

  const [intervention, setIntervention] = useState<InterventionProps | any>();

  const fetchAgents = async () => {
    const { data } = await axios.get(
      '/api/bconnect/webapp/customer/agents/?ordering=Id__Name&size=999999'
    );
    const results = data.results;
    const agents = results.map((agent: any) => {
      return { id: agent.Name, label: agent.Name };
    });
    setAgents(agents);
  };

  const fetchCompanies = async () => {
    const { data } = await axios.get('/api/boi/extractor/clients');
    const results = data.results;
    const companies = results.map((companie: any) => {
      return { id: companie.name, label: companie.name };
    });
    setCompanies(companies);
  };

  const fetchContracts = async () => {
    await axios.get('/api/boi/extractor/interventions/contracts/').then((response) => {
      const data = response.data;
      const contracts = data.map((contract: any) => {
        return { id: contract.contract_name, label: contract.contract_name };
      });
      setContracts(contracts);
    });
  };

  const fetchModuleFamilies = async () => {
    await axios.get('/api/boi/extractor/module-families/').then((response) => {
      const data = response.data;
      const moduleFamilies = data.map((family: any) => {
        return { id: family.Name, label: family.Name };
      });
      setModuleFamilies(moduleFamilies);
    });
  };

  const [reasons, setReasons] = useState<ReasonProps[]>([]);

  const fetchReasons = async () => {
    await axios.get('/api/bconnect/webapp/administrator/references/reason').then((response) => {
      const data = response.data;
      const reasons = data.map((reason: any) => {
        return { id: reason.Id, label: reason.DefaultLabel };
      });
      setReasons(reasons);
    });
  };

  const [supportType, setSupportType] = useState<SupportTypeProps[]>([]);
  const fetchSupportType = async () => {
    await axios.get('/api/boi/extractor/references/support-type/').then((response) => {
      const data = response.data;
      data.sort((a: any, b: any) => a.Label.localeCompare(b.Label));
      const supportType = data.map((type: any) => {
        return { id: type.Id, label: type.Label };
      });
      setSupportType(supportType);
    });
  };

  const [ral, setRal] = useState<RalProps[]>([]);
  const fetchRal = async () => {
    await axios.get('/api/boi/extractor/references/RAL/').then((response) => {
      const data = response.data;
      data.sort((a: any, b: any) => a.Label.localeCompare(b.Label));
      const ral = data.map((color: any) => {
        return { id: color.Id, label: color.Label };
      });
      setRal(ral);
    });
  };

  const [isWaterMeter, setIsWaterMeter] = useState<boolean>();

  const fetchIntervention = async () => {
    setLoading(true);

    axios
      .get(`/api/exocet/unfinished-interventions/${id}`)
      .then(({ data }) => {
        const inter = {
          ...data,
          data
        };
        if (inter.data?.module_family?.toUpperCase() === 'WATERMETER') {
          setIsWaterMeter(true);
        } else {
          setIsWaterMeter(false);
        }

        setIntervention(inter);
        setLoading(false);
      })
      .catch((err) => {
        const errorStatus = err.response.status;
        if (errorStatus === 404) {
          setError(`L'intervention n°${id} n'est pas en cours.`);
        }
      });
  };

  useEffect(() => {
    intervention && getSensorModels(intervention?.data['Marque compteur']);
    fetchBrands();
  }, [intervention]);

  const [brands, setBrands] = useState<BrandProps[]>([]); //brands formatted for select field
  const [rawDataOfBrands, setRawDataOfBrands] = useState<RawBrandProps[]>([]); //all brands
  const [sensorModels, setSensorModels] = useState<SensorModelProps[] | undefined>([]); //formatted array for select field with sensor brands or undefined if FamilyId 1

  const fetchBrands = async () => {
    if (isWaterMeter) {
      await axios.get(`/api/boi/extractor/references/fp2e-sensor-brands/`).then((response) => {
        const data = response.data;
        data.sort((a: any, b: any) => a.Label.localeCompare(b.Label));
        const brands = data.map((brand: any) => {
          return { id: brand.Id, label: brand.Label, code: brand.Code };
        });
        setBrands(brands);
      });
    } else {
      await axios.get(`/api/boi/extractor/references/sensor-brand/`).then((response) => {
        const data = response.data;
        setRawDataOfBrands(data);
        data.sort((a: any, b: any) => a.Label.localeCompare(b.Label));
        const brands = data.map((brand: any) => {
          return { id: brand.Id, label: brand.Label };
        });
        setBrands(brands);
      });
    }
  };

  // to update the list of the available models for the sensor brand selected
  const getSensorModels = async (brandSelected: string) => {
    const data =
      rawDataOfBrands &&
      rawDataOfBrands.find((brand) => {
        if (brand?.Label?.toUpperCase() === brandSelected?.toUpperCase()) {
          return brand;
        }
      });

    let models;
    if (data?.Models) {
      models = data.Models.map((model) => {
        return { id: model?.Id, label: model?.Label?.toUpperCase() };
      });
    }
    setSensorModels(models);
  };

  const radioModes = [
    { id: 1, label: 'HR' },
    { id: 2, label: 'LoRaWAN' },
    { id: 3, label: 'SigFox' }
  ];

  const handleRefresh = (notifObj?: object) => {
    fetchIntervention();
    if (notifObj) notif(notifObj);
  };

  useEffect(() => {
    fetchIntervention();
    fetchAgents();
    fetchCompanies();
    fetchContracts();
    fetchModuleFamilies();
    fetchReasons();
    fetchSupportType();
    fetchRal();
    fetchBrands();
  }, [id]);

  //   load coords gps from intervention object to pass them to Leaflet component
  useEffect(() => {
    if (intervention && intervention.latitude && intervention.longitude) {
      setModuleData({
        module_address: '',
        contract: '',
        city: '',
        latitude: Number(intervention?.latitude),
        longitude: Number(intervention?.longitude),
        device_type: '',
        average_exclusive_module: '',
        contract_label: '',
        contract_exp_date: '',
        pdc_id: '',
        phone: ''
      });
    }
  }, [intervention]);

  const displayDataTextField = (
    fieldName: string,
    fieldLabel: string,
    options: {
      type?: string;
      endAdornment?: JSX.Element;
      read_only?: boolean;
    } = {}
  ) => {
    const isTextarea = options?.type === 'textarea';

    return (
      <TextField
        label={fieldLabel}
        multiline={isTextarea}
        rows={4}
        InputProps={{
          endAdornment: options?.endAdornment,
          readOnly: options?.read_only
        }}
        type={options.type ?? 'text'}
        value={intervention && intervention.data && intervention.data[fieldName]}
        onChange={(event) => {
          if (intervention && intervention.data) {
            setIntervention({
              ...intervention,
              data: {
                ...intervention.data,
                [fieldName]: event.target.value
              }
            });
          }
        }}
        variant={isTextarea ? 'outlined' : 'standard'}
        sx={{
          m: 1,
          minWidth: 300
        }}
        disabled={readOnly}
      />
    );
  };

  const displayDataSelectField = (
    fieldName: string,
    fieldLabel: string,
    fieldValues: Partial<{ id: number; label: string; code?: string }>[],
    options: {
      read_only?: boolean;
      id?: boolean;
      caseSensitive?: boolean;
    } = {}
  ) => {
    return (
      <TextField
        label={fieldLabel}
        select
        InputProps={{
          readOnly: options?.read_only
        }}
        value={(intervention && intervention.data && intervention.data[fieldName]) || ''}
        onChange={(event) => {
          if (intervention && intervention.data) {
            setIntervention({
              ...intervention,
              data: {
                ...intervention.data,
                [fieldName]: event.target.value
              }
            });

            if (fieldName === 'Marque compteur') {
              getSensorModels(event.target.value);
            }
          }
        }}
        sx={{
          m: 1,
          minWidth: 300
        }}
        variant="standard"
        disabled={readOnly}
      >
        <MenuItem value={''}>{''}</MenuItem>
        {fieldValues &&
          fieldValues.map((fieldValue) =>
            options.id ? (
              <MenuItem key={fieldValue.id} value={fieldValue.id}>
                {fieldValue.label}
              </MenuItem>
            ) : options.caseSensitive ? (
              <MenuItem
                key={fieldValue.id}
                value={fieldValue.label && fieldValue.label.toUpperCase()}
              >
                {fieldValue.label && fieldValue.label.toUpperCase()}{' '}
                {fieldValue.code ? '(' + fieldValue.code + ')' : null}
              </MenuItem>
            ) : (
              <MenuItem key={fieldValue.id} value={fieldValue.label}>
                {fieldValue.label}
              </MenuItem>
            )
          )}
      </TextField>
    );
  };

  const displayDateTimePicker = (
    fieldName: string,
    options: {
      fieldLabel?: string;
      isTimestamp?: boolean;
    } = {}
  ) => {
    if (!intervention) return;

    let value = intervention[fieldName] || null;

    if (value !== null) {
      const dateUnix = moment.unix(intervention[fieldName]);
      const date = dateUnix.isValid() ? dateUnix : moment(intervention[fieldName]);
      value = date;
    }

    return (
      <DateTimePicker
        label={options.fieldLabel || fieldName}
        readOnly={true}
        value={value ? new Date(value) : null}
        onChange={(newValue) => {
          if (newValue) {
            const isoString = newValue.toISOString();
            setIntervention({
              ...intervention,
              [fieldName]: isoString
            });
          }
        }}
        slots={{
          textField: (params) => {
            // console.log(params);
            return <TextField {...params} />;
          }
        }}
        disabled={true}
      />
    );
  };

  const displayTextField = (
    fieldName: string,
    fieldLabel: string,
    options: {
      type?: string;
      isDate?: boolean;
      isTimestamp?: boolean;
      endAdornment?: JSX.Element;
      read_only?: boolean;
    } = {}
  ) => {
    const isTextarea = options?.type === 'textarea';

    return (
      <TextField
        label={fieldLabel}
        multiline={isTextarea}
        rows={4}
        InputProps={{
          endAdornment: options?.endAdornment,
          readOnly: options?.read_only
        }}
        type={options.type ?? 'text'}
        value={
          intervention &&
          (intervention[fieldName] === null || typeof intervention[fieldName] === 'undefined'
            ? ''
            : options?.isDate || options?.type === 'date'
              ? moment(intervention[fieldName]).format('DD/MM/YYYY HH:mm')
              : options?.isTimestamp
                ? moment.unix(intervention[fieldName]).format('DD/MM/YYYY HH:mm')
                : intervention[fieldName])
        }
        onChange={(event) => {
          intervention &&
            setIntervention({
              ...intervention,
              [fieldName]: event.target.value
            });
        }}
        variant={isTextarea ? 'outlined' : 'standard'}
        sx={{
          m: 1,
          minWidth: 300
        }}
        disabled={readOnly}
      />
    );
  };

  const displaySelectField = (
    fieldName: string,
    fieldLabel: string,
    fieldValues: Partial<{ id: number | string; label: string }>[],
    options: {
      read_only?: boolean;
    } = {}
  ) => {
    return (
      <TextField
        label={fieldLabel}
        select
        InputProps={{
          readOnly: options?.read_only
        }}
        value={
          intervention &&
          (intervention[fieldName] === null || typeof intervention[fieldName] === 'undefined'
            ? ''
            : intervention[fieldName])
        }
        onChange={(event) => {
          intervention &&
            setIntervention({
              ...intervention,
              [fieldName]: event.target.value
            });
        }}
        sx={{
          m: 1,
          minWidth: 300
        }}
        variant="standard"
        disabled={readOnly}
      >
        <MenuItem value={''} />
        {fieldValues.map((fieldValue) => (
          <MenuItem key={fieldValue.id} value={fieldValue.id}>
            {fieldValue.label}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  const displayDataCheckBox = (
    fieldName: string,
    fieldLabel: string,
    options: {
      disabled?: boolean;
    } = {}
  ) => {
    const checked = !!intervention?.data[fieldName];

    return (
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={(event) => {
                if (intervention && intervention.data) {
                  setIntervention({
                    ...intervention,
                    data: {
                      ...intervention.data,
                      [fieldName]: event.target.checked
                    }
                  });
                }
              }}
              color="primary"
              disabled={options?.disabled}
            />
          }
          label={fieldLabel}
        />
      </div>
    );
  };

  const [imageUrls, setImageUrls] = useState<string[]>([]);

  useEffect(() => {
    if (intervention) {
      const urlString = intervention.data['Lien photo'];
      const imageUrls = urlString?.split(' | ');
      setImageUrls(imageUrls);
    }
  }, [intervention]);

  const [modalMedia, setModalMedia] = useState<string | undefined>();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Number of items to display per page

  const currentPageData = imageUrls?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const [currentModalIndex, setCurrentModalIndex] = useState(0);

  const handleModalMediaChange = (index: number) => {
    const globalIndex = (currentPage - 1) * itemsPerPage + index;
    setCurrentModalIndex(globalIndex);
    setModalMedia(imageUrls[globalIndex]);
  };

  const handlePrevImage = (event: React.MouseEvent) => {
    event.stopPropagation();
    const newIndex = (currentModalIndex - 1 + imageUrls.length) % imageUrls.length;
    setCurrentModalIndex(newIndex);
    setModalMedia(imageUrls[newIndex]);
  };

  const handleNextImage = (event: React.MouseEvent) => {
    event.stopPropagation();
    const newIndex = (currentModalIndex + 1) % imageUrls.length;
    setCurrentModalIndex(newIndex);
    setModalMedia(imageUrls[newIndex]);
  };

  return error ? (
    <NotPendingHandler error={error} />
  ) : (
    <AppPaper>
      <BirdzTitle>Détail de l'intervention n° {id}</BirdzTitle>
      {isLoading ? (
        <LinearProgress />
      ) : (
        intervention && (
          <Grid container>
            <Grid item xs={6}>
              <AppPaper sx={{ mt: 2 }}>
                <BirdzTitle style={{ display: 'flex', alignItems: 'center' }}>
                  <Box>{intervention.reference}</Box>
                  {intervention.anomaly_status === 'IN_ANOMALY' && (
                    <Box sx={{ mt: 1, ml: 1 }}>
                      <ReportProblemIcon style={{ color: '#e60000' }} />
                    </Box>
                  )}
                  {intervention.validation_status === 'VALIDATED' && (
                    <Box sx={{ mt: 1, ml: 1 }}>
                      <CheckCircleIcon style={{ color: 'green' }} />
                    </Box>
                  )}
                </BirdzTitle>

                <Stack spacing={2}>
                  <Box className="d-flex align-items-center">
                    <TextField
                      label="Statut"
                      type={'text'}
                      value={
                        intervention.bconnect_intervention_status === 'Done'
                          ? 'Terminée'
                          : intervention.bconnect_intervention_status
                      }
                      variant={'standard'}
                      sx={{
                        minWidth: 300,
                        width: '100%'
                      }}
                      disabled={true}
                    />
                    <InterventionStatusEditor
                      currentStatus={intervention.bconnect_intervention_status}
                      handleRefresh={handleRefresh}
                    />
                  </Box>
                  {displayDataSelectField('Motif annulation', 'Motif', reasons ?? [])}
                  {displayTextField('cause', 'Cause')}
                  {displayDataTextField('créateur', 'Créateur')}
                  {displayDateTimePicker('intervention_creation_date', {
                    fieldLabel: 'Date de création',
                    isTimestamp: true
                  })}
                  {displayDateTimePicker('Date de mise à jour', {
                    isTimestamp: true
                  })}
                  {displayDateTimePicker('Date d’expiration', {
                    isTimestamp: true
                  })}
                  {/* {displayDateTimePicker('Date de réalisation', {
                            isTimestamp: true,
                            read_only: true
                          })} */}
                  {displayDataTextField('Commentaire intervention', 'Commentaire', {
                    type: 'textarea'
                  })}
                </Stack>
              </AppPaper>
            </Grid>
            <Grid container item xs={6}>
              <Grid item xs={12}>
                <AppPaper sx={{ mt: 2, ml: 2 }}>
                  <BirdzTitle>PROGRAMMATION ET AFFECTATION</BirdzTitle>
                  <Stack spacing={2}>
                    {displayDateTimePicker('Date de début prévue')}
                    {displayDateTimePicker('Date de fin prévue')}
                    {displaySelectField('agent', 'Agent', agents)}
                  </Stack>
                </AppPaper>
              </Grid>
              <Grid item xs={12}>
                <AppPaper sx={{ mt: 2, ml: 2, minHeight: 370 }}>
                  <BirdzTitle>PHOTOS {imageUrls?.length && `(${imageUrls?.length})`}</BirdzTitle>

                  {!imageUrls || imageUrls.length === 0 ? (
                    <Alert severity="info">Aucun média disponible</Alert>
                  ) : (
                    <>
                      <ImageList cols={5} rowHeight={200}>
                        <>
                          {currentPageData?.map((url: string, index: number) => {
                            return (
                              <ImageListItem
                                sx={{ cursor: 'pointer' }}
                                key={index}
                                onClick={() => handleModalMediaChange(index)}
                              >
                                <Box>
                                  <img src={url} alt="" width="100%" />
                                </Box>
                              </ImageListItem>
                            );
                          })}
                        </>
                      </ImageList>

                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button onClick={() => handleDownloadPhotos()}>
                          Télécharger toutes les photos
                        </Button>
                      </Box>

                      <Modal
                        open={modalMedia !== undefined}
                        onClose={() => setModalMedia(undefined)}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <Box
                          sx={{
                            width: '100vw',
                            height: '100vh',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative'
                          }}
                          onClick={() => setModalMedia(undefined)}
                        >
                          {modalMedia && modalMedia !== null && (
                            <>
                              <Box
                                sx={{
                                  position: 'relative',
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    left: '-50px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    cursor: 'pointer',
                                    backgroundColor: 'white',
                                    borderRadius: 8,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                  }}
                                  onClick={handlePrevImage}
                                >
                                  <IconButton>
                                    <ArrowBackIosIcon />
                                  </IconButton>
                                </Box>

                                <Box sx={{ position: 'relative' }}>
                                  <img
                                    src={modalMedia}
                                    alt=""
                                    style={{ maxWidth: '90%', maxHeight: '90%' }}
                                  />

                                  <Tooltip title="Télécharger la photo">
                                    <IconButton
                                      sx={{
                                        position: 'absolute',
                                        top: '0px',
                                        right: '10px',
                                        height: '40px',
                                        backgroundColor: 'white',
                                        borderRadius: 8,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                      }}
                                    >
                                      <a
                                        style={{ color: 'black' }}
                                        href={modalMedia}
                                        download="new-image.jpg"
                                      >
                                        <DownloadIcon />
                                      </a>
                                    </IconButton>
                                  </Tooltip>
                                </Box>

                                <Box
                                  sx={{
                                    position: 'absolute',
                                    right: '30px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    cursor: 'pointer',
                                    backgroundColor: 'white',
                                    borderRadius: 8,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                  }}
                                  onClick={handleNextImage}
                                >
                                  <IconButton>
                                    <ArrowForwardIosIcon />
                                  </IconButton>
                                </Box>
                              </Box>
                            </>
                          )}
                        </Box>
                      </Modal>

                      {imageUrls.length > itemsPerPage && (
                        <Stack alignContent={'flex-end'} alignItems={'end'}>
                          <Pagination
                            count={Math.ceil(imageUrls.length / itemsPerPage)}
                            onChange={(event, page) => {
                              setCurrentPage(page);
                            }}
                          />
                        </Stack>
                      )}
                    </>
                  )}
                </AppPaper>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <AppPaper sx={{ mt: 2 }}>
                  <BirdzTitle>POINT DE SERVICE</BirdzTitle>
                  <Grid container>
                    <Grid item xs={6}>
                      <Box>
                        {displayDataSelectField('CLIENT', 'Client', companies ? companies : [])}
                        {displaySelectField('contract_name', 'Contrat', contracts)}
                        {displayTextField('pds', 'Numéro')}
                        {displaySelectField(
                          'module_family',
                          "Famille d'équipement",
                          moduleFamilies
                        )}
                        {displayDataTextField('Accès', 'Accès')}
                        {displayDataTextField(
                          'Commentaire de localisation',
                          'Commentaire de localisation',
                          {
                            type: 'textarea'
                          }
                        )}
                      </Box>

                      <Grid container spacing={1} sx={{ mt: 1, ml: 1, mr: 1, width: 'auto' }}>
                        <AppPaper>
                          <Grid item xs={12} sx={{ mt: 1, ml: 1 }}>
                            Adresse
                          </Grid>
                          <Grid container item xs={12}>
                            <Box sx={{ width: '10%' }}>
                              {displayDataTextField('Numéro de rue', 'N°')}
                            </Box>
                            <Box sx={{ width: '90%' }}>
                              {displayDataTextField('Nom de la rue', 'Rue')}
                            </Box>
                          </Grid>
                          <Grid container item xs={12}>
                            <Box sx={{ width: '30% ' }}>
                              {displayTextField('zip_code', 'Code postal')}
                            </Box>
                            <Box sx={{ width: '70%' }}>{displayTextField('city', 'Commune')}</Box>
                          </Grid>
                        </AppPaper>
                      </Grid>

                      {displayTextField('latitude', 'Latitude')}
                      {displayTextField('longitude', 'Longitude')}
                    </Grid>
                    <Grid item xs={6}>
                      {moduleData && <DeviceMap moduleData={moduleData} />}
                    </Grid>
                  </Grid>
                </AppPaper>
              </Grid>
            </Grid>

            <AppPaper sx={{ mt: 2 }}>
              <BirdzTitle>CAPTEUR</BirdzTitle>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  Actuel
                </Grid>
                <Grid item xs={6}>
                  Ancien
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  {displayTextField('counter', 'Numéro')}
                </Grid>
                <Grid item xs={6}>
                  {displayTextField('old_counter', 'Numéro', { read_only: true })}
                </Grid>

                <Grid item xs={6}>
                  {displayDataSelectField('Marque compteur', 'Marque', brands ? brands : [], {
                    caseSensitive: true
                  })}
                </Grid>
                <Grid item xs={6}>
                  {displayDataSelectField(
                    'Ancien compteur - Marque',
                    'Marque',
                    brands ? brands : [],
                    { read_only: true }
                  )}
                </Grid>
                {!isWaterMeter && (
                  <Grid item xs={6}>
                    {displayDataSelectField(
                      'Modèle compteur',
                      'Modèle',
                      sensorModels ? sensorModels : [],
                      { caseSensitive: true }
                    )}
                  </Grid>
                )}
                {isWaterMeter && (
                  <Grid item xs={6}>
                    {displayDataTextField('Modèle compteur', 'Modèle')}
                  </Grid>
                )}

                <Grid item xs={6}>
                  {displayDataSelectField(
                    'Ancien compteur - Modèle',
                    'Modèle',
                    sensorModels ? sensorModels : [],
                    { read_only: true }
                  )}
                </Grid>

                <Grid item xs={6}>
                  {displayDataTextField('Diamètre du compteur', 'Diamètre')}
                </Grid>
                <Grid item xs={6}>
                  {displayDataTextField('Ancien compteur - Diamètre', 'Diamètre', {
                    read_only: true
                  })}
                </Grid>

                <Grid item xs={6}>
                  {displayDateTimePicker('counter_installation_date', {
                    isTimestamp: true,
                    fieldLabel: 'Date de pose'
                  })}
                </Grid>
                <Grid item xs={6}>
                  {displayDateTimePicker('Ancien compteur - Date de pose', {
                    fieldLabel: 'Date de pose'
                  })}
                </Grid>

                <Grid item xs={6}>
                  {displayDataTextField('Index de pose', 'Index de pose')}
                </Grid>
                <Grid item xs={6}>
                  {displayDataTextField('Ancien compteur - Index de pose', 'Index de pose', {
                    read_only: true
                  })}
                </Grid>

                <Grid item xs={6}>
                  {displayDateTimePicker('Date de dépose', {
                    isTimestamp: true
                  })}
                </Grid>
                <Grid item xs={6}>
                  {displayDateTimePicker('Ancien compteur - date de dépose', {
                    fieldLabel: 'Date de dépose'
                  })}
                </Grid>

                <Grid item xs={6}>
                  {displayDataTextField('Index de dépose', 'Index de dépose')}
                </Grid>
                <Grid item xs={6}>
                  {displayDataTextField('Ancien compteur - index de dépose', 'Index de dépose', {
                    read_only: true
                  })}
                </Grid>

                <Grid item xs={6}>
                  {displayDataTextField('Dernier index lu', 'Dernier index lu')}
                </Grid>
                <Grid item xs={6}>
                  {displayDataTextField('Ancien compteur - Dernier index lu', 'Dernier index lu', {
                    read_only: true
                  })}
                </Grid>

                <Grid item xs={6}>
                  {displayDataTextField('Dernier Index', 'Dernier index')}
                </Grid>
                <Grid item xs={6}>
                  {displayDataTextField('Ancien compteur - Dernier Index', 'Dernier index', {
                    read_only: true
                  })}
                </Grid>
              </Grid>
            </AppPaper>

            <AppPaper sx={{ mt: 2 }}>
              <BirdzTitle>MODULE</BirdzTitle>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  Actuel
                </Grid>
                <Grid item xs={6}>
                  Ancien
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  {displayTextField('device_id', 'Adresse radio')}
                </Grid>
                <Grid item xs={6}>
                  {displayTextField('old_device_id', 'Adresse radio', {
                    read_only: true
                  })}
                </Grid>

                <Grid item xs={6}>
                  {displayTextField('device_model', 'Type')}
                </Grid>
                <Grid item xs={6}></Grid>

                <Grid item xs={6}>
                  {displayDataSelectField('Module - Mode radio', 'Mode radio', radioModes ?? [], {
                    id: true
                  })}
                </Grid>
                <Grid item xs={6}>
                  {displayDataSelectField(
                    'Ancien module - Mode radio',
                    'Mode radio',
                    radioModes ?? [],
                    { id: true, read_only: true }
                  )}
                </Grid>

                <Grid item xs={6}>
                  {displayDataTextField('Module - Canal TX', 'Canal TX')}
                </Grid>
                <Grid item xs={6}>
                  {displayDataTextField('Ancien module - Canal TX', 'Canal TX', {
                    read_only: true
                  })}
                </Grid>

                <Grid item xs={6}>
                  {displayDataTextField('Module - Canal LR 1', 'Canal LR 1')}
                </Grid>
                <Grid item xs={6}>
                  {displayDataTextField('Ancien module - Canal LR 1', 'Canal LR 1', {
                    read_only: true
                  })}
                </Grid>

                <Grid item xs={6}>
                  {displayDataTextField('Module - Canal LR 2', 'Canal LR 2')}
                </Grid>
                <Grid item xs={6}>
                  {displayDataTextField('Ancien module - Canal LR 2', 'Canal LR 2', {
                    read_only: true
                  })}
                </Grid>

                <Grid item xs={6}>
                  {displayDataCheckBox('Module - DST', 'DST', {
                    disabled: true
                  })}
                </Grid>
                <Grid item xs={6}>
                  {displayDataCheckBox('Ancien module - DST', 'DST', {
                    disabled: true
                  })}
                </Grid>

                <Grid item xs={6}>
                  {displayDataCheckBox('Module - LoRaWAN auto-connect', 'LoRaWAN auto-connect', {
                    disabled: true
                  })}
                </Grid>
                <Grid item xs={6}>
                  {displayDataCheckBox(
                    'Ancien module - LoRaWAN auto-connect',
                    'LoRaWAN auto-connect',
                    {
                      disabled: true
                    }
                  )}
                </Grid>

                <Grid item xs={6}>
                  {displayDataCheckBox('Module - index horaire', 'Index horaire', {
                    disabled: true
                  })}
                </Grid>
                <Grid item xs={6}>
                  {displayDataCheckBox('Ancien module - index horaire', 'Index horaire', {
                    disabled: true
                  })}
                </Grid>

                <Grid item xs={6}>
                  {displayDataTextField('Module - LrwAppEUI', 'LrwAppEUI')}
                </Grid>
                <Grid item xs={6}>
                  {displayDataTextField('Ancien module - LrwAppEUI', 'LrwAppEUI', {
                    read_only: true
                  })}
                </Grid>

                <Grid item xs={6}>
                  {displayDataTextField('Module - Clé métier', 'Clé métier')}
                </Grid>
                <Grid item xs={6}>
                  {displayDataTextField('Ancien module - Clé métier', 'Clé métier', {
                    read_only: true
                  })}
                </Grid>

                <Grid item xs={6}>
                  {displayDateTimePicker('device_installation_date', {
                    fieldLabel: 'Date de pose'
                  })}
                </Grid>

                <Grid item xs={6}>
                  {displayDateTimePicker('Ancien module - date de pose', {
                    fieldLabel: 'Date de pose'
                  })}
                </Grid>

                <Grid item xs={6}>
                  {displayDataTextField('Module - index de pose', 'Index de pose')}
                </Grid>
                <Grid item xs={6}>
                  {displayDataTextField('Ancien module - index de pose', 'Index de pose', {
                    read_only: true
                  })}
                </Grid>

                <Grid item xs={6}>
                  {displayDateTimePicker('Module - date de dépose', {
                    fieldLabel: 'Date de dépose'
                  })}
                </Grid>
                <Grid item xs={6}>
                  {displayDateTimePicker('Ancien module - date de dépose', {
                    fieldLabel: 'Date de dépose'
                  })}
                </Grid>

                <Grid item xs={6}>
                  {displayDataTextField('Module - Index de dépose', 'Index de dépose')}
                </Grid>
                <Grid item xs={6}>
                  {displayDataTextField('Ancien module - index de dépose', 'Index de dépose', {
                    read_only: true
                  })}
                </Grid>
              </Grid>
            </AppPaper>

            <AppPaper sx={{ mt: 2 }}>
              <BirdzTitle>EQUIPEMENT PARENT</BirdzTitle>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  Actuel
                </Grid>
                <Grid item xs={6}>
                  Ancien
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  {displayDataTextField('Repeteur Parent', 'Adresse radio')}
                </Grid>
                <Grid item xs={6}>
                  {displayDataTextField('Ancien Repeteur Parent', 'Adresse radio', {
                    read_only: true
                  })}
                </Grid>

                <Grid item xs={6}>
                  {displayDataSelectField(
                    'Repeteur Parent - Mode radio',
                    'Mode radio',
                    radioModes ?? [],
                    { id: true }
                  )}
                </Grid>
                <Grid item xs={6}>
                  {displayDataSelectField(
                    'Ancien Repeteur Parent - Mode radio',
                    'Mode radio',
                    radioModes ?? [],
                    { id: true, read_only: true }
                  )}
                </Grid>

                <Grid item xs={6}>
                  {displayDataTextField('Repeteur Parent - Canal TX', 'Canal TX')}
                </Grid>
                <Grid item xs={6}>
                  {displayDataTextField('Ancien Repeteur Parent - Canal TX', 'Canal TX', {
                    read_only: true
                  })}
                </Grid>

                <Grid item xs={6}>
                  {displayDataTextField('Repeteur Parent - Canal RX', 'Canal RX')}
                </Grid>
                <Grid item xs={6}>
                  {displayDataTextField('Ancien Repeteur Parent - Canal RX', 'Canal RX', {
                    read_only: true
                  })}
                </Grid>

                <Grid item xs={6}>
                  {displayDataTextField('Repeteur Parent - LrwAppEUI', 'LrwAppEUI')}
                </Grid>
                <Grid item xs={6}>
                  {displayDataTextField('Ancien Repeteur Parent - LrwAppEUI', 'LrwAppEUI', {
                    read_only: true
                  })}
                </Grid>

                <Grid item xs={6}>
                  {displayDateTimePicker('Repeteur Parent - date de pose', {
                    fieldLabel: 'Date de pose'
                  })}
                </Grid>
                <Grid item xs={6}>
                  {displayDateTimePicker('Ancien Repeteur Parent - date de pose', {
                    fieldLabel: 'Date de pose'
                  })}
                </Grid>

                <Grid item xs={6}>
                  {displayDateTimePicker('Repeteur Parent - date de dépose', {
                    fieldLabel: 'Date de dépose'
                  })}
                </Grid>
                <Grid item xs={6}>
                  {displayDateTimePicker('Ancien Repeteur Parent - date de dépose', {
                    fieldLabel: 'Date de dépose'
                  })}
                </Grid>

                <Grid item xs={6}>
                  {displayDataSelectField(
                    'Repeteur Parent - Type de Support',
                    'Type de support',
                    supportType ?? []
                  )}
                </Grid>
                <Grid item xs={6}>
                  {displayDataSelectField(
                    'Ancien Repeteur Parent - Type de Support',
                    'Type de support',
                    supportType ?? [],
                    { read_only: true }
                  )}
                </Grid>

                <Grid item xs={6}>
                  {displayDataSelectField('Repeteur Parent - RAL', 'RAL', ral ?? [])}
                </Grid>
                <Grid item xs={6}>
                  {displayDataSelectField('Ancien Repeteur Parent - RAL', 'RAL', ral ?? [], {
                    read_only: true
                  })}
                </Grid>

                <Grid item xs={6}>
                  {displayDataTextField(
                    'Repeteur Parent - Référence de Support',
                    'Référence de support'
                  )}
                </Grid>
                <Grid item xs={6}>
                  {displayDataTextField(
                    'Ancien Repeteur Parent - Référence de Support',
                    'Référence de support',
                    { read_only: true }
                  )}
                </Grid>

                <Grid item xs={6}>
                  {displayDataTextField('Repeteur Parent - Numéro de rue', 'N°')}
                </Grid>
                <Grid item xs={6}>
                  {displayDataTextField('Ancien Repeteur Parent - Numéro de rue', 'N°', {
                    read_only: true
                  })}
                </Grid>

                <Grid item xs={6}>
                  {displayDataTextField(
                    'Repeteur Parent - attribut du numéro de rue',
                    'Attribut du n° de rue'
                  )}
                </Grid>
                <Grid item xs={6}>
                  {displayDataTextField(
                    'Ancien Repeteur Parent - attribut du numéro de rue',
                    'Attribut du n° de rue',
                    {
                      read_only: true
                    }
                  )}
                </Grid>

                <Grid item xs={6}>
                  {displayDataTextField('Repeteur Parent - Nom de la rue', 'Nom de la rue')}
                </Grid>
                <Grid item xs={6}>
                  {displayDataTextField('Ancien Repeteur Parent - Nom de la rue', 'Nom de la rue', {
                    read_only: true
                  })}
                </Grid>

                <Grid item xs={6}>
                  {displayDataTextField('Repeteur Parent - Code postal', 'Code postal')}
                </Grid>
                <Grid item xs={6}>
                  {displayDataTextField('Ancien Repeteur Parent - Code postal', 'Code postal', {
                    read_only: true
                  })}
                </Grid>

                <Grid item xs={6}>
                  {displayDataTextField('Repeteur Parent - Commune', 'Commune')}
                </Grid>
                <Grid item xs={6}>
                  {displayDataTextField('Ancien Repeteur Parent - Commune', 'Commune', {
                    read_only: true
                  })}
                </Grid>

                <Grid item xs={6}>
                  {displayDataTextField('Repeteur Parent - Accès', 'Accès')}
                </Grid>
                <Grid item xs={6}>
                  {displayDataTextField('Ancien Repeteur Parent - Accès', 'Accès', {
                    read_only: true
                  })}
                </Grid>

                <Grid item xs={6}>
                  {displayDataTextField('Repeteur Parent - localisation', 'Localisation')}
                </Grid>
                <Grid item xs={6}>
                  {displayDataTextField('Ancien Repeteur Parent - localisation', 'Localisation', {
                    read_only: true
                  })}
                </Grid>
              </Grid>
            </AppPaper>
          </Grid>
        )
      )}

      <BirdzNotif options={notifOptions} />
    </AppPaper>
  );
}

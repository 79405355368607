import React, { useState } from 'react';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import ImportHandler from '../../../../../Tools/import/ImportHandler';
import { useTourFormContext } from '../../../../context/tourFormContext';
import { TourDevice, removeDuplicatesByModule } from '../types';
import SiBirdzImport from './SiBirdzImport';
import { useFetchDataRefsContext } from '../../../../../../contexts/FetchDataRefsContext';
import { StepsProps } from '../../../../types';
import ClearableSelect from '../../../../../Tools/ClearableSelect';
import { useGetBReaderClients } from '../../../../../../hooks/datarefs';
import { AppPaper } from '@applications-terrains/birdz-react-library';

const PatrimonyStep = ({ role }: StepsProps) => {
  const { tourData, setTourData, setRefreshDevicesStep, setCurrentStep } = useTourFormContext();
  const { dataRefs, setDataRefs } = useFetchDataRefsContext();
  const { data: clients } = useGetBReaderClients();

  const [tab, setTab] = useState<number>(0);
  const tabs = {
    0: 'Via CSV',
    1: 'Via SI Birdz'
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <Tabs value={tab} onChange={(_, newValueTab: number) => setTab(newValueTab)}>
          {Object.entries(tabs).map(([key, label]) => (
            <Tab key={key} label={label} />
          ))}
        </Tabs>
      </Box>

      {tab === 0 && (
        <>
          {role === 'boi' ? (
            <AppPaper sx={{ pt: 2 }}>
              <Grid container className="d-flex" alignItems={'center'} sx={{ mb: 2 }}>
                <Grid item xs={2}>
                  CLIENT*
                </Grid>
                <Grid item xs={10}>
                  <ClearableSelect
                    options={
                      clients
                        ? clients?.map((client) => ({
                            label: client.name,
                            value: client.id
                          }))
                        : []
                    }
                    onChange={(e) => {
                      setTourData({
                        ...tourData,
                        client: Number(e?.value)
                      });
                    }}
                  />
                </Grid>
              </Grid>

              {tourData.client && (
                <ImportHandler
                  url={`/api/boi/breader/import-tours-file/`}
                  cb={(patrimonyData: object[]) => {
                    const uniquePatrimonyData = removeDuplicatesByModule(
                      patrimonyData as TourDevice[]
                    );
                    setRefreshDevicesStep(true);
                    setTourData({
                      ...tourData,
                      patrimonyStep: uniquePatrimonyData as TourDevice[]
                    });
                    setCurrentStep('devicesStep');
                    setDataRefs({
                      ...dataRefs,
                      tour_devices_filtered: null,
                      tour_devices: {
                        count: uniquePatrimonyData.length,
                        results: uniquePatrimonyData as TourDevice[]
                      }
                    });
                  }}
                />
              )}
            </AppPaper>
          ) : role === 'boe' ? (
            <ImportHandler
              url={`/api/boe/breader/import-tours-file/`}
              cb={(patrimonyData: object[]) => {
                const uniquePatrimonyData = removeDuplicatesByModule(patrimonyData as TourDevice[]);
                setRefreshDevicesStep(true);
                setTourData({
                  ...tourData,
                  patrimonyStep: uniquePatrimonyData as TourDevice[]
                });
                setCurrentStep('devicesStep');
                setDataRefs({
                  ...dataRefs,
                  tour_devices_filtered: null,
                  tour_devices: {
                    count: uniquePatrimonyData.length,
                    results: uniquePatrimonyData as TourDevice[]
                  }
                });
              }}
            />
          ) : (
            <></>
          )}
        </>
      )}
      {tab === 1 && <SiBirdzImport role={role} />}
    </>
  );
};

export default PatrimonyStep;

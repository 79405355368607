import React, { useEffect, useState } from 'react';
import {
  BirdzDialog,
  useDialog,
  AppPaper,
  BirdzTitle,
  SearchForm
} from '@applications-terrains/birdz-react-library';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Button, Grid, IconButton } from '@mui/material';
import { BirdzNotif, useNotif } from '@applications-terrains/birdz-react-library';
import Search from '@mui/icons-material/Search';
import { useFetchContext } from '../../../../contexts/fetchContext';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import moment from 'moment';
import { PiFileCsv } from 'react-icons/pi';
import BTable from '../../../Tools/Table/BTable';
import CancelIcon from '@mui/icons-material/Cancel';

const ToursListBOE = () => {
  const endpoint = '/api/boe/breader/tours/';
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const { notif, notifOptions } = useNotif();
  const fetchContext = useFetchContext();
  const [refresh, setRefresh] = useState(false);

  const [filterString, setFilterString] = useState<string>('');
  const [cities, setCities] = useState<{ label: string; value: string }[] | undefined>(undefined);
  const [frameTypes, setFrameTypes] = useState<{ label: string; value: number }[] | undefined>(
    undefined
  );

  useEffect(() => {
    refresh && setRefresh(false);
  }, [refresh]);

  const fetchCities = async () => {
    await axios.get('/api/boe/breader/tours/cities/').then((response) => {
      const data = response.data;
      const cities = data.map((city: string) => {
        return { value: city, label: city };
      });
      setCities(cities);
    });
  };

  const fetchFrameTypes = async () => {
    type Frame_type = { id: number; label: string };
    await axios.get('/api/boe/breader/frame-types/').then((response) => {
      const data: Frame_type[] = response.data.results;
      const types = data.map((type) => {
        return { value: type.id, label: type.label };
      });
      const typesSorted = types.sort((a, b) => a.label.localeCompare(b.label));
      setFrameTypes(typesSorted);
    });
  };

  useEffect(() => {
    !cities && fetchCities();
    !frameTypes && fetchFrameTypes();
  }, [cities, frameTypes]);

  const listFields = [
    { name: 'id', label: '#' },
    { name: 'name', label: 'Nom' },
    {
      name: 'created_at',
      label: 'Date de création',
      transform: (value: any) => {
        return value ? moment.unix(value).format('DD/MM/YYYY à HH:mm:ss') : '-';
      }
    },
    { name: 'statistics', label: 'Relevé / Nb total - %' },
    { name: 'reader_name', label: 'Releveur' },
    {
      name: 'closing_date',
      label: 'Retour',
      transform: (value: any) => {
        return value ? moment(value).format('DD/MM/YYYY à HH:mm:ss') : '-';
      }
    },
    {
      name: 'canceled',
      label: 'Statut',
      transform: (value: any) => {
        return value && value === true ? 'Annulée' : 'Valide';
      }
    }
  ];

  const actions = [
    {
      name: 'details',
      label: 'Voir les détails',
      render: (tour: { id: number }) => {
        return (
          <IconButton component={Link} to={`/breader/tours/details/${tour.id}`}>
            <Search fontSize="small" />
          </IconButton>
        );
      }
    },
    {
      name: 'cancel-tour',
      label: 'Annuler la tournée',
      render: (model: { id: number; canceled: boolean }) => {
        if (!model.canceled) {
          return (
            <IconButton
              onClick={() => {
                confirmDialog({
                  title: 'Annuler la tournée',
                  content: 'Êtes-vous sûr de vouloir annuler cette tournée ?',
                  onValidate: () => {
                    axios.post(`${endpoint}${model.id}/cancel/`).then(
                      () => {
                        setRefresh(true);
                        notif({
                          content: 'La tournée a été annulée avec succès',
                          type: 'success'
                        });
                        fetchContext.toggleNeedToFetch(true);
                      },
                      () => {
                        notif({
                          content: "Une erreur est survenue lors de l'annulation",
                          type: 'error'
                        });
                      }
                    );
                    closeDialog();
                  },
                  onCancel: () => {
                    closeDialog();
                  }
                });
              }}
            >
              <CancelIcon fontSize="small" />
            </IconButton>
          );
        }
        return <IconButton />;
      }
    }
  ];

  const searchFields = [
    {
      name: 'created_at_after',
      id: 'search_by_date_after',
      label: 'Entre le ',
      type: 'datepicker'
    },
    {
      name: 'created_at_before',
      id: 'search_by_date_before',
      label: 'Et le',
      type: 'datepicker'
    },
    {
      name: 'name',
      label: 'Nom',
      options: {
        identifier: 'name',
        source: endpoint,
        label: 'name',
        searchIsMulti: false
      }
    },
    {
      name: 'city',
      label: 'Commune',
      type: 'select',
      options: {
        values: cities
      }
    },
    {
      name: 'reader',
      label: 'Releveur',
      options: {
        identifier: 'id',
        source: '/api/boe/breader/readers/',
        label: 'name',
        searchIsMulti: false
      }
    },
    {
      name: 'frame_type',
      id: 'search_by_type',
      label: 'Type',
      type: 'select-multiple',
      options: {
        values: frameTypes
      }
    }
  ];

  return (
    <AppPaper>
      <Grid justifyContent="space-between" container>
        <Grid item>
          <BirdzTitle>Liste des tournées</BirdzTitle>
        </Grid>
        <Grid item>
          <Link to={'/breader/tours/add'}>
            <Button variant="contained" sx={{ mt: 3 }}>
              <AddCircleIcon sx={{ mr: 1 }} /> Ajouter une tournée
            </Button>
          </Link>
        </Grid>
      </Grid>
      <AppPaper sx={{ mb: 2 }}>
        <Grid sx={{ my: 2, p: 0 }}>
          <SearchForm
            fields={searchFields}
            onSubmit={(filterString) => {
              setFilterString(filterString);
            }}
          />
        </Grid>
      </AppPaper>
      {!fetchContext.needToFetch && (
        <BTable
          model="tours"
          endpoint={endpoint + filterString.replace('&', '?')}
          fields={listFields}
          actions={actions}
          exportButtons={[
            {
              name: 'Exporter en csv',
              fileName: 'tours',
              type: 'csv',
              id: 'breader-tours-export-button',
              icon: <PiFileCsv />,
              requestEndpoint: '/api/boe/breader/tours/export-listing/',
              keepQueryParams: false,
              onSuccess: () => {
                notif({
                  type: 'success',
                  content: 'Export réussi'
                });
              }
            }
          ]}
        />
      )}

      <BirdzDialog options={dialogOptions} />
      <BirdzNotif options={notifOptions} />
    </AppPaper>
  );
};

export default ToursListBOE;

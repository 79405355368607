import React, { useEffect, useState } from 'react';
import {
  BirdzDialog,
  CheckboxField,
  InputField,
  useDialog
} from '@applications-terrains/birdz-react-library';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useFetchContext } from '../../../../contexts/fetchContext';
import ModalWithLoader, { RequestStatus } from '../../../Tools/ModalWithLoader/ModalWithLoader';
import { Formik, Form } from 'formik';
import { Box, Button, Typography } from '@mui/material';
import axios from 'axios';
import { Support, SupportId } from './SupportsList';
import PhoneField from '../../../Tools/Fields/PhoneField';
import { useGetUsers } from '../../../../hooks/datarefs';
import ClearableSelect from '../../../Tools/ClearableSelect';
import PagePaper from '../../../Tools/Pages/PagePaper';
import { phoneRegExp } from '../../../../utils';

const SupportForm = ({
  name,
  supportId,
  setSupportId,
  newSupportSource
}: {
  name: 'support' | 'reader';
  supportId: SupportId;
  setSupportId: (value: SupportId) => void;
  newSupportSource: 'users' | 'new';
}) => {
  const { id } = useParams();
  const endpoint =
    name === 'support'
      ? '/api/boi/breader/supports/'
      : name === 'reader'
        ? '/api/boi/breader/readers/'
        : null;
  const { toggleNeedToFetch } = useFetchContext();
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [support, setSupport] = useState<Support | null>(null);

  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleClose = () => {
    setSupportId(null);
  };

  useEffect(() => {
    if (supportId && supportId !== 'add' && !support) {
      setRequestStatus('pending');
      setIsFetching(true);
      endpoint &&
        axios.get(endpoint + supportId).then((response) => {
          setSupport(response.data);
          setTimeout(() => {
            setRequestStatus(null);
            setIsFetching(false);
          }, 400);
        });
    }
  }, [endpoint, supportId]);

  const handleSubmit = (values: Support) => {
    setRequestStatus('pending');
    const { password, id: suppId, client, ...rest } = values;
    const formattedObj: { [key: string]: string | number | boolean | null } = {
      ...rest,
      client: typeof client === 'string' ? parseInt(client) : client
    };
    if (suppId && suppId !== 'add') {
      formattedObj.id = typeof suppId === 'string' ? parseInt(suppId) : suppId;
    }
    if (suppId === 'add') {
      formattedObj.password = password;
    }

    const method = suppId === 'add' ? 'post' : 'put';
    const url = `${endpoint}${suppId === 'add' ? '' : +suppId}`;

    const errorTranslations = {
      'Maximum number of allowed users already reached': "Nombre maximum d'utilisateurs atteint",
      'user already used by another B-READER client.':
        'Utilisateur déjà utilisé pour un autre client B-READER',
      'email already used': "L'email renseigné est déjà utilisé. Veuillez en saisir un autre",
      default: `Une erreur est survenue lors de la ${suppId === 'add' ? 'création' : 'modification'} du ${name === 'support' ? 'gestionnaire' : name === 'reader' ? 'releveur' : null}`
    };
    closeDialog();
    return axios[method](url, { ...formattedObj, phone: formattedObj.phone || null })
      .then(() => {
        setRequestStatus('success');
        setTimeout(() => {
          handleClose();
          toggleNeedToFetch(true);
        }, 1500);
      })
      .catch((err) => {
        setRequestStatus('error');
        const errorMessage =
          'error' in err.response.data && err.response.data.error in errorTranslations
            ? errorTranslations[err.response.data.error as keyof typeof errorTranslations]
            : err.response.data.error === ''
              ? errorTranslations['email already used']
              : errorTranslations['default'];
        setErrorMessage(errorMessage);
      });
  };

  const { data: users } = useGetUsers(newSupportSource === 'users');

  const validationSchema =
    newSupportSource === 'users' && supportId === 'add'
      ? Yup.object({
          user_id: Yup.number().required('Vous devez sélectionner un utilisateur')
        })
      : Yup.object({
          name: Yup.string().required('Vous devez renseigner les nom et prénom'),
          email: Yup.string()
            .email('Vous devez renseigner un email valide')
            .required('Vous devez renseigner un email'),
          phone: Yup.string()
            .nullable()
            .notRequired()
            .test(
              'is-valid-phone',
              'Vous devez renseigner un numéro de téléphone valide',
              (value) => !value || phoneRegExp.test(value) // Applique la regex si une valeur est saisie
            )
            .test(
              'min-length',
              'Numéro de téléphone trop court',
              (value) => !value || value.length >= 10
            )
            .test(
              'max-length',
              'Numéro de téléphone trop long',
              (value) => !value || value.length <= 11
            ),
          password:
            supportId === 'add'
              ? Yup.string().required('Vous devez renseigner un mot de passe')
              : Yup.string(),
          main_contact: Yup.bool(),
          is_active: Yup.bool(),
          user_id: Yup.number().nullable()
        });

  return (
    <ModalWithLoader
      openModal={true}
      onClose={() => {
        handleClose();
      }}
      status={newSupportSource === 'users' && !users ? 'pending' : requestStatus}
      timeout={requestStatus === 'error' ? 3000 : 1500}
      customErrorMessage={errorMessage}
      action={
        newSupportSource === 'users' && !users
          ? 'Récupération des utilisateurs'
          : `${isFetching ? 'Récupération des données' : supportId === 'add' ? 'Création' : 'Modification'} du ${name === 'support' ? 'gestionnaire' : name === 'reader' ? 'releveur' : null}`
      }
    >
      {(supportId === 'add' || support) && (
        <Formik
          validationSchema={validationSchema}
          initialValues={
            support || {
              name: null,
              email: null,
              phone: null,
              password: null,
              main_contact: false,
              is_active: false,
              user_id: null
            }
          }
          onSubmit={(values) => {
            confirmDialog({
              title: `Confirmer ${supportId ? 'les modifications' : 'la création'}`,
              content: 'Souhaitez-vous confirmer vos saisies ?',
              onValidate: () => {
                const supportObj = {
                  ...values,
                  client: id as string | number,
                  id: supportId || ''
                };
                handleSubmit(supportObj);
              },
              onCancel: () => {
                handleClose();
              },
              okButtonLabel: 'Oui',
              cancelButtonLabel: 'Non'
            });
          }}
        >
          {({ isValid, dirty, setFieldValue }) => {
            return (
              <Form>
                <PagePaper
                  blockScroll
                  title={
                    <Typography variant="overline">
                      {supportId === 'add' ? 'Ajouter' : 'Modifier'} un{' '}
                      {name === 'support' ? 'gestionnaire' : name === 'reader' ? 'releveur' : null}
                    </Typography>
                  }
                >
                  {supportId === 'add' && newSupportSource === 'users' && users ? (
                    <Box sx={{ p: 0.1, minHeight: '350px' }}>
                      <ClearableSelect
                        placeholder="Selectionner un utilisateur"
                        options={users
                          ?.map((user) => ({
                            label: user.name,
                            value: user.id
                          }))
                          .sort((a, b) => a.label.localeCompare(b.label))}
                        onChange={(user) => {
                          setFieldValue('user_id', user?.value || null);
                        }}
                      />
                    </Box>
                  ) : (
                    <Box sx={{ pt: 1 }}>
                      <Box sx={{ height: 80 }}>
                        <InputField name="name" type="text" label="Nom" size="small" fullWidth />
                      </Box>
                      <Box sx={{ height: 80 }}>
                        <PhoneField />
                      </Box>
                      <Box sx={{ height: 80 }}>
                        <InputField
                          name="email"
                          type="email"
                          label="Email"
                          size="small"
                          fullWidth
                        />
                      </Box>
                      <Box sx={{ height: 70 }}>
                        <InputField
                          name="password"
                          type="password"
                          label="Mot de passe"
                          size="small"
                          fullWidth
                        />
                      </Box>
                      {name === 'support' && (
                        <>
                          <CheckboxField
                            id="mainContact"
                            name="main_contact"
                            label="Contact principal"
                          />
                          <CheckboxField id="is_active" name="is_active" label="Actif" />
                        </>
                      )}
                    </Box>
                  )}

                  <Box sx={{ textAlign: 'center' }}>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ m: 2 }}
                      disabled={!dirty || !isValid}
                    >
                      Enregistrer
                    </Button>

                    <Button
                      className="btn-space"
                      onClick={() => {
                        confirmDialog({
                          title: 'Annuler les saisies',
                          content: 'Souhaitez-vous annuler vos saisies ?',
                          onValidate: () => {
                            handleClose();
                          },
                          onCancel: () => {
                            handleClose();
                          },
                          okButtonLabel: 'Oui',
                          cancelButtonLabel: 'Non'
                        });
                      }}
                    >
                      Annuler
                    </Button>
                  </Box>
                </PagePaper>
              </Form>
            );
          }}
        </Formik>
      )}
      <BirdzDialog options={dialogOptions} />
    </ModalWithLoader>
  );
};

export default SupportForm;

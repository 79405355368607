import React, { useEffect, useState } from 'react';
import { BirdzNotif, BirdzTitle, useNotif } from '@applications-terrains/birdz-react-library';
import { Box, Button, Typography } from '@mui/material';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { PiFileCsv } from 'react-icons/pi';
import { saveAs } from 'file-saver';
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';
import { TourDetail } from '../types';
import { authService } from '../../../../../..';
import PagePaper from '../../../../../Tools/Pages/PagePaper';
import RoundaboutRightIcon from '@mui/icons-material/RoundaboutRight';
import { capitalizeFirstLetter } from '../../../../../../utils';
import EmptyPageWithLoader from '../../../../../Tools/Pages/EmptyPageWIthLoader';
import DotsLoader from '../../../../../Tools/DotsLoader/DotsLoader';
import TourDetailsDevices from './TourDetailsDevices';
import TourDetailsFilters from './TourDetailsFilters';
import { deviceStatuses } from '../TourMap/TourMapUtils';
import HandleCreateTour from './HandleCreateTour';
import { useFetchDataRefsContext } from '../../../../../../contexts/FetchDataRefsContext';
import PieChartWrapper from '../../../../../Tools/Charts/PieChartWrapper';

L.Icon.Default.mergeOptions({
  iconRetinaUrl,
  iconUrl,
  shadowUrl
});

export default function TourDetails() {
  const { id } = useParams();
  const [tour, setTour] = useState<TourDetail>();
  const [statistics, setStatistics] = useState<{ name: string; value: number; last?: boolean }[]>(
    []
  );
  const [exportInProgress, setExportInProgress] = useState<boolean>(false);
  const { notif, notifOptions } = useNotif();
  const { setDataRefs } = useFetchDataRefsContext();
  const endpoint = authService.canAccessBOI()
    ? `/api/boi/breader/tours/${id}`
    : authService.canAccessBReaderBOE()
      ? `/api/boi/breader/tours/${id}`
      : '';

  const [isBoe, setIsBoe] = useState<boolean>();

  const fetchTourDetails = async () => {
    await axios.get(endpoint).then((response) => {
      const data = response.data;
      setTour(data);
    });
  };

  useEffect(() => {
    fetchTourDetails();
    authService.canAccessBReaderBOE() ? setIsBoe(true) : setIsBoe(false);
    //cleanup on unmount
    return () => {
      setDataRefs({
        tour_devices_selected: null,
        tour_devices_filtered: null,
        tour_devices: null
      });
    };
  }, []);

  useEffect(() => {
    const statsToTransform = tour ? tour.statistics : {};
    const statsToAdd = [];

    for (const property in statsToTransform) {
      let data;
      switch (property) {
        case 'total':
          data = { name: 'Total', value: statsToTransform[property], last: true };
          break;
        case 'devices_collected':
          data = {
            name: capitalizeFirstLetter(deviceStatuses['COLLECTED']),
            value: statsToTransform[property]
          };
          break;
        case 'devices_tocollect':
          data = {
            name: capitalizeFirstLetter(deviceStatuses['TOCOLLECT']),
            value: statsToTransform[property]
          };
          break;
        case 'devices_partially':
          data = {
            name: capitalizeFirstLetter(deviceStatuses['PARTIALLY']),
            value: statsToTransform[property]
          };
          break;
        case 'devices_failure':
          data = {
            name: capitalizeFirstLetter(deviceStatuses['FAILURE']),
            value: statsToTransform[property]
          };
          break;
        default:
          data = { name: capitalizeFirstLetter(property), value: statsToTransform[property] };
          break;
      }
      statsToAdd.push(data);
    }
    setStatistics(statsToAdd);
  }, [tour]);

  const exportTour = async () => {
    setExportInProgress(true);

    await axios
      .get(`/api/bo${isBoe ? 'e' : 'i'}/breader/tours/${id}/export/`, {
        responseType: 'blob',
        headers: {
          Accept: '*/*'
        }
      })
      .then((response) => {
        const [, filename] = response.headers['content-disposition'].split('filename=');
        notif({
          content: "L'export a été réalisé avec succès",
          type: 'success'
        });
        saveAs(response.data, filename);
      })
      .catch(() => {
        notif({
          content: "L'export a échoué",
          type: 'error'
        });
      })
      .finally(() => {
        setExportInProgress(false);
      });
  };

  const title = (
    <Box>
      <BirdzTitle className="m-0">{'Détails de la tournée'}</BirdzTitle>
      <Box className="d-flex align-items-center" sx={{ color: '#666666', mt: 0.5 }}>
        <Box
          className="d-flex align-items-center justify-content-center"
          sx={{
            borderRadius: '50%',
            border: '2px solid #666666',
            width: '30px',
            height: '30px',
            overflow: 'hidden',
            mr: 1
          }}
        >
          {<RoundaboutRightIcon />}
        </Box>
        {tour?.name ? (
          capitalizeFirstLetter(tour?.name)
        ) : (
          <DotsLoader message="récupération des données en cours" />
        )}
      </Box>
    </Box>
  );

  return (
    <PagePaper bordered title={title}>
      {!tour ? (
        <EmptyPageWithLoader />
      ) : (
        <Box className="d-flex flex-column flex-grow-1">
          <TourDetailsFilters />

          <TourDetailsDevices endpoint={endpoint + '/devices'} tour={tour} />

          <Box sx={{ mt: 2 }}>
            <PieChartWrapper
              label="Statistiques de la tournée"
              data={statistics}
              displayMode="values"
              loading={!tour}
            />
          </Box>

          <Box className="d-flex justify-content-between h-100" sx={{ mt: 'auto', pt: 2 }}>
            <Box textAlign="left">
              <Button
                size="small"
                disabled={exportInProgress}
                variant="outlined"
                onClick={() => exportTour()}
              >
                <PiFileCsv />
                <Typography sx={{ ml: 1 }}>Exporter la tournée</Typography>
              </Button>
            </Box>
            {isBoe && <HandleCreateTour tour={tour} />}
          </Box>
        </Box>
      )}
      <BirdzNotif options={notifOptions} />
    </PagePaper>
  );
}

import React, { useEffect, useState } from 'react';
import { useTourFormContext } from '../../../../context/tourFormContext';
import { Button, Grid } from '@mui/material';
import { AppPaper } from '@applications-terrains/birdz-react-library';
import axios, { AxiosResponse } from 'axios';
import ContractsDropdown, { Contract } from '../../../../../Contracts/ContractsDropdown';
import ModalWithLoader, {
  RequestStatus
} from '../../../../../Tools/ModalWithLoader/ModalWithLoader';
import ClearableSelect from '../../../../../Tools/ClearableSelect';
import { useFetchDataRefsContext } from '../../../../../../contexts/FetchDataRefsContext';
import { StepsProps } from '../../../../types';
import { useGetBReaderClients } from '../../../../../../hooks/datarefs';
import { removeDuplicatesByModule } from '../types';

type City = {
  label: string;
  zip_code: string;
  insee: string;
};

const SiBirdzImport = ({ role }: StepsProps) => {
  const [selectedContract, setSelectedContract] = useState<string>();
  const [selectedCities, setSelectedCities] = useState<string[]>();
  const [cities, setCities] = useState<City[]>([]);
  const [clientId, setClientId] = useState<number>();
  const { data: clients } = useGetBReaderClients();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(null);
  const { tourData, setTourData, setRefreshDevicesStep, setCurrentStep } = useTourFormContext();
  const [contracts, setContracts] = useState<Contract[]>([]);
  const { setDataRefs } = useFetchDataRefsContext();

  const fetchContracts = async () => {
    await axios.get(`/api/boi/breader/clients/`).then((response) => {
      const data = response.data.results.filter((item: any) => item.id === clientId);
      setContracts(data[0].contracts_data);
    });
  };

  useEffect(() => {
    role === 'boi' && clientId && fetchContracts();
  }, [clientId]);

  useEffect(() => {
    requestStatus === 'success' &&
      setTimeout(() => {
        setRequestStatus(null);
      }, 2000);
  }, [requestStatus]);

  useEffect(() => {
    setIsLoading(true);
    let url;
    if (clientId && role === 'boi') {
      url = `/api/boi/breader/contracts-cities/?client=${clientId}`;
    }
    if (role === 'boe') {
      url = `/api/boe/breader/contracts-cities/`;
    }

    url &&
      axios
        .get(url)
        .then((response: AxiosResponse) => {
          const cities = response.data;
          if (cities.length) {
            cities.unshift({
              label: 'Toutes',
              zip_code: '',
              insee: 'allCities'
            });
          }
          setCities(cities);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
  }, [axios, clientId, role]);

  const handleClose = () => {
    setRequestStatus(null);
  };
  return (
    <AppPaper sx={{ pt: 2 }}>
      {role === 'boi' && (
        <Grid container className="d-flex" alignItems={'center'} sx={{ mb: 2 }}>
          <Grid item xs={2}>
            CLIENT*
          </Grid>
          <Grid item xs={10}>
            <ClearableSelect
              isLoading={isLoading}
              options={
                clients
                  ? clients?.map((client) => ({
                      label: client.name,
                      value: client.id
                    }))
                  : []
              }
              onChange={(e) => {
                setClientId(Number(e?.value));
                setTourData({
                  ...tourData,
                  client: Number(e?.value)
                });
              }}
            />
          </Grid>
        </Grid>
      )}
      <Grid container className="d-flex" alignItems={'center'} sx={{ mb: 2 }}>
        <Grid item xs={2}>
          CONTRAT
        </Grid>
        <Grid item xs={10}>
          {role === 'boi' ? (
            <ContractsDropdown
              isMulti={false}
              onChange={(contractNb) => setSelectedContract(contractNb as string)}
              contractsData={contracts}
              searchKey="contract_ve"
              disabled={!clientId}
            />
          ) : role === 'boe' ? (
            <ContractsDropdown
              isMulti={false}
              onChange={(contractNb) => setSelectedContract(contractNb as string)}
              endpoint={`/api/boe/breader/contracts/`}
              searchKey="contract_ve"
            />
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      <Grid container className="d-flex" alignItems={'center'} sx={{ mb: 2 }}>
        <Grid item xs={2}>
          COMMUNE(S)
        </Grid>
        <Grid item xs={10}>
          <ClearableSelect
            isLoading={isLoading}
            options={cities.map((city) => ({
              label: city.label + ' ' + city.zip_code,
              value: city.insee
            }))}
            onChange={(cities) => {
              setSelectedCities(cities?.map((el) => el.value));
            }}
            disabled={(!isLoading && cities.length === 0) || (role === 'boi' && !clientId)}
            isMulti
          />
        </Grid>
      </Grid>
      <Grid container className="d-flex" justifyContent={'end'} sx={{ gap: 1 }}>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            setRequestStatus('pending');
            const baseUrl = `/api/${role === 'boi' ? 'boi' : role === 'boe' ? 'boe' : ''}/breader/data-from-patrimony/?contract_code=${selectedContract || ''}`;
            const url = selectedCities?.includes('allCities')
              ? baseUrl
              : `${baseUrl}&insee_code=${selectedCities?.join(',') || ''}`;
            axios
              .get(url)
              .then((response: any) => {
                //remove modules' occurence
                const data = removeDuplicatesByModule(response.data);
                setRefreshDevicesStep(true);
                if (data.length > 0) {
                  setCurrentStep('devicesStep');
                  setTourData({
                    ...tourData,
                    patrimonyStep: data,
                    devicesStep: null
                  });
                  setDataRefs({
                    tour_devices: { count: data.length, results: data }
                  });
                  setRequestStatus('success');
                } else {
                  setRequestStatus('successWithNoResults');
                  setTourData({
                    ...tourData,
                    patrimonyStep: null,
                    devicesStep: null
                  });
                }
              })
              .catch(() => setRequestStatus('error'));
          }}
        >
          Rechercher
        </Button>
      </Grid>
      <ModalWithLoader
        openModal={requestStatus === 'pending'}
        onClose={() => {
          handleClose();
        }}
        action="Import des devices"
        status={requestStatus}
        setStatus={setRequestStatus}
      />
    </AppPaper>
  );
};

export default SiBirdzImport;

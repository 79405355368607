import React from 'react';
import { TextField, Checkbox, InputAdornment, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import useDebounce from './hooks/useDebounce';

interface CustomTextFieldProps {
  error?: boolean;
  size: 'small' | 'medium';
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | null) => void;
  placeholder?: string;
  displaySelectAll?: boolean;
  setAll?: (value: boolean) => void;
  isMulti: boolean;
  all?: boolean;
}

const CustomTextField = styled(
  ({
    displaySelectAll,
    setAll,
    all,
    isMulti,
    ...props
  }: CustomTextFieldProps & { [key: string]: any }) => {
    const [action, setAction] = useState<'Sélectionner' | 'Désélectionner'>();

    useEffect(() => {
      setAction(all ? 'Désélectionner' : 'Sélectionner');
    }, [all]);

    const InputProps = {
      ...props.InputProps
    };
    // https://github.com/mui/material-ui/issues/19479#issuecomment-1067480032
    if (displaySelectAll === true)
      InputProps['startAdornment'] = (
        <>
          <InputAdornment position="start">
            <Tooltip
              title={`${action} tous les clients`}
              placement="top"
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -10]
                      }
                    }
                  ]
                }
              }}
            >
              <Checkbox
                checked={all}
                onChange={(e) => {
                  setAll && setAll(e.target.checked);
                  setAction(e.target.checked ? 'Désélectionner' : 'Sélectionner');
                }}
                size="small"
              />
            </Tooltip>
          </InputAdornment>
          {props.InputProps.startAdornment}
        </>
      );

    return isMulti ? (
      <TextField
        {...props}
        InputProps={InputProps}
        inputProps={{ ...props.inputProps, value: props.inputValue }}
        // onBlur={() => {
        //   props.onChange && props.onChange(null);
        // }}
      />
    ) : (
      <TextField {...props} InputProps={InputProps} />
    );
  }
)<CustomTextFieldProps>(({ error }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: error ? '#d32f2f' : undefined
    }
  }
}));

const ContractsCustomTextField = ({
  onSearch,
  error,
  size,
  placeholder,
  displaySelectAll,
  setAll,
  all,
  isMulti,
  ...props
}: {
  onSearch: (data: string) => void;
  placeholder?: string;
  error: boolean;
  displaySelectAll?: boolean;
  size: 'small' | 'medium';
  setAll?: (value: boolean) => void;
  all?: boolean;
  isMulti: boolean;
}) => {
  const [inputValue, setInputValue] = useState('');
  const debouncedValue = useDebounce(inputValue, 300); // Déclencher après 300ms d'inactivité

  useEffect(() => {
    if (debouncedValue.length >= 3) {
      onSearch(debouncedValue);
    }
  }, [debouncedValue, onSearch]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (isMulti && event.key === 'Backspace' && inputValue.length > 0) {
      // Empêche la suppression de la dernière option si le champ de recherche contient du texte
      event.stopPropagation();
    }
  };

  return (
    <CustomTextField
      {...props}
      isMulti={isMulti}
      inputvalue={inputValue}
      onChange={(e) => setInputValue(e ? e?.target?.value : '')}
      error={error}
      placeholder={placeholder}
      size={size}
      displaySelectAll={displaySelectAll}
      setAll={setAll}
      all={all}
      onKeyDown={handleKeyDown}
    />
  );
};

export default ContractsCustomTextField;

import React, { useEffect, useState } from 'react';
import {
  AppPaper,
  BirdzTitle,
  ListPage,
  SearchField,
  ListField
} from '@applications-terrains/birdz-react-library';
import Visibility from '@mui/icons-material/Visibility';
import Edit from '@mui/icons-material/Edit';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  useGetAnomalyRules,
  useGetBConnectAgents,
  useGetCities,
  useGetModuleFamilies
} from '../../../hooks/datarefs';
import ExportPopup from './ExportPopup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import useReinitializeForm from '../../../hooks/useReinitializeForm';
import axios from 'axios';
import { CompanyProps } from './types';
import { useInterventionFiltersContext } from './context/interventionsFiltersContext';
import { styled } from '@mui/material';
import PageSizeSelector, { PaginationType } from '../../Tools/Table/PageSizeSelector';
import PaginationComponent from '../../Tools/Table/PaginationComponent';
import BSearchForm from '../../Tools/Search/BSearchForm';
import Bookmarks from '../../Tools/Search/Bookmarks';

// Composant stylisé avec TypeScript
const StyledTableWrapper = styled(Box)(() => {
  return {
    // https://stackoverflow.com/questions/73688604/how-can-i-show-a-horizontal-scrollbar-at-the-top-of-the-table
    transform: 'rotateX(180deg)',
    // https://stackoverflow.com/questions/19230289/use-transition-on-webkit-scrollbar
    backgroundColor: 'rgba(0,0,0,0)',
    transition: 'background-color 300ms',
    backgroundClip: 'text',
    overflowX: 'scroll',

    '.table-content': {
      transform: 'rotateX(180deg)'
    },
    '&::-webkit-scrollbar': {
      height: '8px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'inherit',
      borderRadius: '10px',
      cursor: 'grab' // Curseur par défaut
    },
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.3)'
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent'
    },
    '& .MuiPagination-root': {
      display: 'none'
    }
  };
});

export default function Interventions({ target = 'all' }: { target: 'all' | 'pending' }) {
  const endpoints = {
    all: '/api/boi/extractor/interventions/',
    pending: '/api/exocet/unfinished-interventions'
  };
  const endpoint = endpoints[target];

  const [pagination, setPagination] = useState<PaginationType>({
    pageSize: 25, // Default value
    currentPage: 1,
    totalItems: null
  });

  const { data: cities } = useGetCities();
  const { data: agents } = useGetBConnectAgents();
  const { data: moduleFamilies } = useGetModuleFamilies();
  const { data: anomalyRules } = useGetAnomalyRules();
  const reset = useReinitializeForm();

  const {
    filtersDataString,
    setFiltersDataString,
    filtersDataValues,
    setFiltersDataValues,
    setCurrentBookmark
  } = useInterventionFiltersContext();

  const [contractString, setContractString] = useState<string>('');
  const [companies, setCompanies] = useState<CompanyProps[]>([]);

  useEffect(() => {
    if (reset) {
      setContractString('');
    }
  }, [reset]);

  const fetchCompanies = async () => {
    const { data } = await axios.get('/api/boi/extractor/clients/?size=999');
    const companies = data.results;
    setCompanies(companies);
  };

  useEffect(() => {
    target === 'all' && fetchCompanies();
  }, [target]);

  const translate = (familyName: string) => {
    switch (familyName) {
      case 'WaterMeter':
        return 'Compteur & module EAU';
      case 'Repetition':
        return 'Equipement de répétition';
      case 'FirePost':
        return 'Poteau incendie';
      case 'WaterHydrant':
        return 'Bouche de lavage';
      case 'LeakDetection':
        return 'Prélocalisateur Gutermann';
      case 'PAV':
        return 'FilLink';
      case 'LeakPreLocator':
        return 'Prélocalisateur Sewerin';
      case 'LuKa':
        return 'Luka';
      case 'API-BI':
        return 'API-BI';
      default:
        return familyName;
    }
  };

  const searchFields = [
    target === 'all' && {
      name: 'client',
      label: 'Client',
      multiple: true,
      options: {
        identifier: 'value',
        label: 'label',
        values: (companies || [])?.map((company) => {
          return { value: company.id, label: company.name };
        })
      }
    },
    target === 'all' && {
      name: 'contract_id',
      label: 'Contrat',
      multiple: true,
      queryParamsSourceField: { key: 'client', param: 'client_id__in' },
      objectFormat: { label: 'contract_name', value: 'contract_id' },
      options: {
        source: '/api/boi/extractor/interventions/contracts'
      }
    },
    target === 'pending' && {
      name: 'client',
      label: 'Client',
      searchable: true,
      objectFormat: { label: 'name', value: 'bconnect_id' },
      searchTargetKey: 'name',
      options: {
        source: '/api/boi/extractor/bconnect-clients/'
      }
    },
    {
      name: 'reference',
      label: 'Reference',
      type: 'select',
      searchable: true,
      searchTargetKey: 'reference',
      objectFormat: { label: 'reference', value: 'reference' },
      options: {
        source:
          target === 'all'
            ? '/api/boi/extractor/interventions/references/'
            : '/api/boi/exocet/unfinished-interventions/references/'
      }
    },
    {
      name: 'intervention_type__in',
      label: "Type d'intervention",
      multiple: true,
      options: {
        values: [
          { value: 'EligibilityAudit', label: 'Audit' },
          { value: 'AttributeInstall', label: "Installation d'un attribut" },
          { value: 'AttributeMaintenance', label: "Maintenance d'un attribut" },
          { value: 'MeterInstall', label: "Installation d'un compteur" },
          { value: 'MeterMaintenance', label: "Maintenance d'un compteur" },
          { value: 'DeviceInstall', label: "Installation d'un module" },
          { value: 'DeviceDump', label: "Dépose d'un module" },
          { value: 'DeviceReinstall', label: "Repose d'un module" },
          { value: 'DeviceMaintenance', label: "Maintenance d'un module" },
          { value: 'RepeaterMaintenance', label: "Maintenance d'un répéteur / Bridge" },
          { value: 'VisualIndex', label: 'Relève visuelle' },
          { value: 'RadioIndex', label: 'Radio-relevé' },
          { value: 'DeviceUpdate', label: 'MAJ des informations du module' },
          { value: 'RepeaterUpdate', label: 'MAJ des informations du répéteur' },
          {
            value: 'MeterMaintenanceWithCs',
            label: "Maintenance d'un compteur avec enquête branchement"
          },
          {
            value: 'DeviceMaintenanceWithCs',
            label: "Maintenance d'un module avec enquête branchement"
          },
          {
            value: 'MeterInstallWithCs',
            label: "Installation d'un compteur avec enquête branchement"
          },
          {
            value: 'DeviceInstallWithCs',
            label: "Installation d'un module avec enquête branchement"
          },
          { value: 'ConnectionSurveyWithCs', label: 'Enquête branchement' },
          { value: 'DeviceInstallLight', label: "Installation d'un module - Light" },
          { value: 'DeviceMaintenanceLight', label: "Maintenance d'un module - Light" },
          { value: 'RadioIndexRead', label: 'Radio-relevé unitaire' }
        ]
      }
    },
    target === 'all' && {
      name: 'validation_status',
      label: 'Statut validation',
      type: 'select',
      options: {
        values: [
          { value: 'TO_VALIDATE', label: 'A valider' },
          { value: 'VALIDATED', label: 'Validée' }
        ]
      }
    },
    target === 'all' && {
      name: 'anomaly_status',
      label: 'Statut anomalie',
      type: 'select',
      options: {
        values: [
          { value: 'WITHOUT_ANOMALY', label: 'Sans anomalie' },
          { value: 'IN_ANOMALY', label: 'En anomalie' },
          { value: 'CORRECTED', label: 'Corrigée' }
        ]
      }
    },
    target === 'all' && {
      name: 'anomaly_type',
      label: "Type d'anomalie",
      type: 'select-multiple',
      options: {
        values: (anomalyRules || [])?.map((rule) => {
          return { value: rule.label, label: rule.label };
        })
      },
      seeMore: true
    },
    target === 'all' && {
      name: 'agent',
      label: 'Agent',
      type: 'select-multiple',
      options: {
        identifier: 'value',
        label: 'label',
        values: (agents || [])?.map((agent) => {
          return { value: agent.name, label: agent.name };
        })
      },
      seeMore: true
    },
    target === 'all' && { name: 'counter', label: 'Capteur', type: 'text', seeMore: true },
    target === 'all' && { name: 'device_id', label: 'Module', type: 'text', seeMore: true },
    target === 'all' && {
      name: 'module_family',
      label: 'Famille de module',
      type: 'select-multiple',
      options: {
        values: (moduleFamilies || [])?.map((family) => {
          return { value: family.Name, label: translate(family.Name) };
        })
      },
      seeMore: true
    },
    target === 'all' && {
      name: 'city',
      label: 'Commune',
      type: 'select-multiple',
      options: {
        values:
          cities && cities.length
            ? (cities || []).map((city) => {
                return {
                  value: city,
                  label: city
                };
              })
            : []
      },
      seeMore: true
    },
    target === 'all' && {
      name: 'equipment_parent',
      label: 'Equipement parent',
      type: 'text',
      seeMore: true
    },
    target === 'all' && { name: 'pds', label: 'PDS Client', type: 'text', seeMore: true },
    target === 'all' && { type: 'blank', seeMore: true },
    target === 'all' && { type: 'blank', seeMore: true },
    target === 'all' && { type: 'divider', seeMore: true },
    target === 'all' && { type: 'blank', seeMore: true },
    target === 'all' && {
      name: 'created_at_after',
      label: 'Création entre le',
      type: 'datepicker',
      seeMore: true
    },
    target === 'all' && {
      name: 'created_at_before',
      label: 'et le',
      type: 'datepicker',
      seeMore: true
    },
    target === 'all' && { type: 'blank', seeMore: true },
    target === 'all' && {
      name: 'closing_at_after',
      label: 'Réalisation entre le',
      type: 'datepicker',
      seeMore: true
    },
    target === 'all' && {
      name: 'closing_at_before',
      label: 'et le',
      type: 'datepicker',
      seeMore: true
    },
    target === 'all' && { type: 'blank', seeMore: true },
    target === 'all' && {
      name: 'updated_at_after',
      label: 'Mise à jour entre le',
      type: 'datepicker',
      seeMore: true
    },
    target === 'all' && {
      name: 'updated_at_before',
      label: 'et le',
      type: 'datepicker',
      seeMore: true
    },
    target === 'all' && { type: 'blank', seeMore: true },
    target === 'all' && {
      name: 'exported_at_after',
      label: 'Exportée entre le',
      type: 'datepicker',
      seeMore: true
    },
    target === 'all' && {
      name: 'exported_at_before',
      label: 'et le',
      type: 'datepicker',
      seeMore: true
    }
  ].filter((el) => el) as SearchField[];

  const listFields = [
    target === 'all' && {
      name: 'anomaly_status',
      label: 'Statut anomalie',
      orderable: true,
      transform: (value: string) => {
        if (value === 'IN_ANOMALY') {
          return (
            <Tooltip title="En anomalie">
              <span>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <ReportProblemIcon style={{ color: '#e60000' }} />
                </Box>
              </span>
            </Tooltip>
          );
        } else if (value === 'WITHOUT_ANOMALY') {
          return <Box sx={{ display: 'flex', justifyContent: 'center' }}>Aucune</Box>;
        } else if (value === 'CORRECTED') {
          return <Box sx={{ display: 'flex', justifyContent: 'center' }}>Corrigée</Box>;
        } else return value || '-';
      }
    },
    target === 'all' && {
      name: 'validation_status',
      label: 'Statut validation',
      orderable: true,
      transform: (value: string) => {
        switch (value) {
          case 'VALIDATED':
            return (
              <Tooltip title="Validée">
                <span>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CheckCircleIcon style={{ color: 'green' }} />
                  </Box>
                </span>
              </Tooltip>
            );
          case 'TO_VALIDATE':
            return (
              <Tooltip title="A valider">
                <span>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <EditCalendarIcon style={{ color: '#0000e6' }} />
                  </Box>
                </span>
              </Tooltip>
            );

          default:
            return value || '-';
        }
      }
    },
    { name: `${target === 'all' ? 'data.' : ''}CLIENT`, label: 'Client', orderable: true },
    { name: 'contract_name', label: 'Contrat', orderable: true },
    { name: 'reference', label: 'Réf', orderable: true },
    {
      name: 'module_family',
      label: 'Famille de module',
      orderable: true,
      transform(value: any) {
        return translate(value);
      }
    },
    { name: 'city', label: 'Commune', orderable: true },
    { name: 'agent', label: 'Agent', orderable: true },
    {
      name: 'intervention_type',
      label: 'Type intervention',
      orderable: true,
      transform(value: string) {
        switch (value) {
          case 'EligibilityAudit':
            return 'Audit';
          case 'AttributeInstall':
            return "Installation d'un attribut";
          case 'AttributeMaintenance':
            return "Maintenance d'un attribut";
          case 'MeterInstall':
            return "Installation d'un compteur";
          case 'MeterMaintenance':
            return "Maintenance d'un compteur";
          case 'DeviceInstall':
            return "Installation d'un module";
          case 'DeviceDump':
            return "Dépose d'un module";
          case 'DeviceReinstall':
            return "Repose d'un module";
          case 'DeviceMaintenance':
            return "Maintenance d'un module";
          case 'RepeaterMaintenance':
            return "Maintenance d'un répéteur / Bridge";
          case 'VisualIndex':
            return 'Relève visuelle';
          case 'RadioIndex':
            return 'Radio-relevé';
          case 'DeviceUpdate':
            return 'MAJ des informations du module';
          case 'RepeaterUpdate':
            return 'MAJ des informations du répéteur';
          case 'MeterMaintenanceWithCs':
            return "Maintenance d'un compteur avec enquête branchement";
          case 'DeviceMaintenanceWithCs':
            return "Maintenance d'un module avec enquête branchement";
          case 'MeterInstallWithCs':
            return "Installation d'un compteur avec enquête branchement";
          case 'DeviceInstallWithCs':
            return "Installation d'un module avec enquête branchement";
          case 'ConnectionSurveyWithCs':
            return 'Enquête branchement';
          case 'DeviceInstallLight':
            return "Installation d'un module - Light";
          case 'DeviceMaintenanceLight':
            return "Maintenance d'un module - Light";
          case 'RadioIndexRead':
            return 'Radio-relevé unitaire';
          default:
            return value;
        }
      }
    },
    {
      name: 'bconnect_intervention_status',
      label: 'Statut intervention',
      orderable: true,
      transform(value: string) {
        if (value === 'Done') {
          return 'Terminée';
        } else {
          return value;
        }
      }
    },
    target === 'all' && {
      name: 'data.Motif annulation',
      label: 'Motif statut',
      orderable: true
    },
    {
      name: 'intervention_creation_date',
      label: 'Date de création',
      orderable: true,
      transform(value: any) {
        return value ? (
          moment(value).format('DD/MM/YYYY') + ' à ' + moment(value).format('HH:mm')
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>-</Box>
        );
      }
    },
    target === 'all' && {
      name: 'updated_at',
      label: 'Date de mise à jour',
      orderable: true,
      transform(value: number) {
        return value ? (
          moment.unix(value).format('DD/MM/YYYY') + ' à ' + moment.unix(value).format('HH:mm')
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>-</Box>
        );
      }
    },
    target === 'all' && {
      name: 'closing_date',
      label: 'Date de réalisation',
      orderable: true,
      transform(value: any) {
        return value ? (
          moment(value).format('DD/MM/YYYY') + ' à ' + moment(value).format('HH:mm')
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>-</Box>
        );
      }
    },
    target === 'all' && {
      name: 'nb_exports',
      label: 'Nb exports',
      orderable: true,
      transform(value: number) {
        return <Box sx={{ display: 'flex', justifyContent: 'center' }}>{value}</Box>;
      }
    },
    target === 'all' && { name: 'last_export_date', label: 'Date dernier export', orderable: true },
    { name: 'pds', label: 'PDS', orderable: true },
    {
      name: 'counter',
      label: 'Capteur',
      orderable: true,
      transform(value: any) {
        return value ? value : <Box sx={{ display: 'flex', justifyContent: 'center' }}>-</Box>;
      }
    },
    {
      name: 'device_id',
      label: 'Module',
      orderable: true,
      transform(value: any) {
        return value ? value : <Box sx={{ display: 'flex', justifyContent: 'center' }}>-</Box>;
      }
    },
    {
      name: 'equipment_parent',
      label: 'Equipement parent',
      orderable: true,
      transform(value: any) {
        return value ? value : <Box sx={{ display: 'flex', justifyContent: 'center' }}>-</Box>;
      }
    }
  ].filter((el) => el) as ListField[];

  const actions = [
    {
      name: 'view',
      label: 'Consulter',
      render: (row: any) => {
        return (
          <IconButton
            component={Link}
            to={
              target === 'all'
                ? `/bconnect/extractor/interventions/view/${row.mo_bconnect_id}`
                : `/bconnect/interventions/pending/details/${row.mo_bconnect_id}`
            }
          >
            <Visibility fontSize="small" />
          </IconButton>
        );
      }
    },
    {
      name: 'edit',
      label: 'Modifier',
      render: (row: any) => {
        if (row.bconnect_intervention_status === 'Done' && row.client) {
          return (
            <IconButton
              component={Link}
              to={`/bconnect/extractor/interventions/edit/${row.mo_bconnect_id}`}
            >
              <Edit fontSize="small" />
            </IconButton>
          );
        }
        return <></>;
      }
    }
  ];

  return (
    <AppPaper>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <BirdzTitle>
            {target === 'all' ? 'Interventions terminées' : 'Suivi des interventions'}
          </BirdzTitle>
        </Grid>
        <Bookmarks />
      </Grid>

      <BSearchForm
        defaultValues={filtersDataValues}
        fields={searchFields}
        setSearchString={(filterString) => {
          setFiltersDataString(contractString + filterString);
        }}
        setSearchValues={setFiltersDataValues}
        onReinitialize={() => setCurrentBookmark(null)}
      />
      {target === 'all' && <ExportPopup filter={filtersDataString} />}

      <StyledTableWrapper
        className={`table-wrapper`}
        sx={{
          pt: 1,
          ml: '-8px'
        }}
      >
        <Box
          sx={{
            overflowY: 'auto',
            minWidth: 'fit-content',
            px: 1
          }}
          className="table-content"
        >
          <ListPage
            paginationOptions={pagination}
            endpoint={endpoint}
            filters={filtersDataString}
            fields={listFields}
            defaultOrder={['-intervention_updated_date']}
            actions={actions}
            displayPaginationOptions={false}
            onLoadItems={(_, response) =>
              setPagination({ ...pagination, totalItems: response.count })
            }
          />
        </Box>
      </StyledTableWrapper>
      <Box
        className="d-flex justify-content-between align-items-center"
        sx={{
          pt: 2,
          mt: 'auto'
        }}
      >
        <PaginationComponent
          pagination={pagination}
          setPagination={setPagination}
          displayResultsNumber={false}
        />
        <PageSizeSelector pagination={pagination} setPagination={setPagination} />
      </Box>
    </AppPaper>
  );
}

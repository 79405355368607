import React from 'react';
import { Grid } from '@mui/material';
import LicencesStats from './LicencesStats';
import NotationsStats from './NotationsStats';

function Dashboard() {
  return (
    <Grid container spacing={2} sx={{ mt: 2 }}>
      <Grid item xs={6}>
        <LicencesStats />
      </Grid>

      <Grid item xs={6}>
        <NotationsStats />
      </Grid>
    </Grid>
  );
}

export default Dashboard;

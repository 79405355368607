import React, { ReactNode, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  TextField
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AppPaper, BirdzDialog, useDialog } from '@applications-terrains/birdz-react-library';
import { useNavigate, useParams } from 'react-router-dom';
import DevicesStep from './BOE/Tours/TourForm/DevicesStep/DevicesStep';
import { StepType } from '../BReader/BOE/Tours/TourForm/types';
import { useTourFormContext } from './context/tourFormContext';
import PatrimonyStep from './BOE/Tours/TourForm/PatrimonyStep/PatrimonyStep';
import ReaderStep from './BOE/Tours/TourForm/ReaderStep/ReaderStep';
import ConfirmModalContent from './BOE/Tours/TourForm/ConfirmModalContent';
import axios from 'axios';
import ModalWithLoader, { RequestStatus } from '../Tools/ModalWithLoader/ModalWithLoader';
import { omit } from 'lodash';

type TourFormProps = {
  role: string;
  onCancel?: () => void;
};

export default function TourForm({ role, onCancel }: TourFormProps) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(null);
  const { tourData, setTourData, currentStep, setCurrentStep, resetAll } = useTourFormContext();
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();

  useEffect(() => {
    return () => {
      resetAll();
    };
  }, []);

  const handleClose = () => {
    closeDialog();
  };

  const handleCurrentStep = (step: StepType) => setCurrentStep(currentStep === step ? null : step);

  return (
    <AppPaper>
      <Box sx={{ mt: 3 }}>
        <TextField
          id="outlined-basic"
          label="Nom de la tournée *"
          variant="outlined"
          className="w-100"
          value={tourData && tourData.name}
          onChange={(e) => setTourData({ ...tourData, name: e.target.value })}
        />
      </Box>
      {!id && (
        <Step
          disabled={!tourData.name}
          expanded={tourData.name.length > 0 && currentStep === 'patrimonyStep'}
          setExpanded={() => handleCurrentStep('patrimonyStep')}
          title={'Import patrimoine'}
        >
          <PatrimonyStep role={role} />
        </Step>
      )}

      <Step
        disabled={!tourData.name || !tourData.patrimonyStep}
        expanded={tourData.name.length > 0 && currentStep === 'devicesStep'}
        setExpanded={() => handleCurrentStep('devicesStep')}
        title={'Création de la tournée'}
      >
        <DevicesStep role={role} />
      </Step>
      <Step
        disabled={!tourData.name || !tourData.patrimonyStep}
        expanded={tourData.name.length > 0 && currentStep === 'readerStep'}
        setExpanded={() => handleCurrentStep('readerStep')}
        title={'Attribution par releveur'}
      >
        <ReaderStep role={role} />
      </Step>

      <Box sx={{ textAlign: 'end' }}>
        <Button
          className="btn-space"
          onClick={() => {
            confirmDialog({
              title: 'Annuler les saisies',
              content: 'Souhaitez-vous annuler vos saisies ?',
              onValidate: () => {
                handleClose();
                onCancel ? onCancel() : navigate(-1);
              },
              onCancel: () => {
                handleClose();
              },
              okButtonLabel: 'Oui',
              cancelButtonLabel: 'Non'
            });
          }}
        >
          Annuler
        </Button>
        <Button
          onClick={() => {
            confirmDialog({
              title: `Confirmer la création de la tournée`,
              content: <ConfirmModalContent />,
              onValidate: () => {
                setRequestStatus('pending');
                const payload = {
                  name: tourData.name,
                  ...(role === 'boi' && { client: tourData.client }),
                  reader: tourData.readerStep?.value,
                  devices: tourData?.devicesStep?.map((device) => {
                    const moduleId =
                      device.MODULE ||
                      device.device_id ||
                      device.data?.MODULE ||
                      device.data?.device_id;
                    return {
                      device_type: device.device_type,
                      frame_type: device.frame_type,
                      device_id: moduleId,
                      data:
                        'data' in device
                          ? omit(
                              {
                                ...device.data,
                                MODULE: moduleId
                              },
                              ['device_type', 'frame_type', 'device_id']
                            )
                          : {
                              ATTR_NUM_RUE_ABO: device.ATTR_NUM_RUE_ABO,
                              COMMUNE_ABO: device.COMMUNE_ABO,
                              COMPTEUR: device.COMPTEUR,
                              COORD_X: device.COORD_X,
                              COORD_Y: device.COORD_Y,
                              NUM_RUE_ABO: device.NUM_RUE_ABO,
                              PDS: device.PDS,
                              RUE_ABO: device.RUE_ABO,
                              MODULE: moduleId
                            }
                    };
                  })
                };
                axios
                  .post(
                    `/api/${role === 'boi' ? 'boi' : role === 'boe' ? 'boe' : ''}/breader/tours/`,
                    payload
                  )
                  .then(() => {
                    setRequestStatus('success');
                    closeDialog();
                    setTimeout(() => {
                      navigate(-1);
                    }, 2000);
                  })
                  .catch(() => {
                    setRequestStatus('error');
                    closeDialog();
                  });
              },
              onCancel: () => {
                handleClose();
              },
              okButtonLabel: 'Oui',
              cancelButtonLabel: 'Non'
            });
          }}
          variant="contained"
          sx={{ m: 2 }}
          disabled={
            !tourData.name ||
            // !tourData.devicesStep ||
            !tourData.readerStep ||
            requestStatus === 'pending'
          }
        >
          {requestStatus === 'pending' && (
            <CircularProgress size={18} thickness={5} sx={{ mr: 1 }} />
          )}{' '}
          Enregistrer
        </Button>
      </Box>

      <BirdzDialog options={dialogOptions} />
      <ModalWithLoader
        openModal={requestStatus === 'pending'}
        onClose={() => {
          handleClose();
          setRequestStatus(null);
        }}
        action="Création de la tournée"
        status={requestStatus}
        setStatus={setRequestStatus}
      />
    </AppPaper>
  );
}

const Step = ({
  title,
  children,
  expanded,
  setExpanded,
  disabled
}: {
  expanded: boolean;
  setExpanded: () => void;
  title: string;
  children: ReactNode;
  disabled: boolean;
}) => {
  return (
    <Accordion
      disabled={disabled}
      square={false}
      variant="outlined"
      sx={{ mt: 2, borderRadius: '4px' }}
      expanded={expanded}
      disableGutters={true}
    >
      <AccordionSummary
        onClick={setExpanded}
        expandIcon={<ExpandMoreIcon />}
        sx={{ mb: 0, border: 'none' }}
      >
        <h3>{title}</h3>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  AppPaper,
  BirdzDialog,
  BirdzTitle,
  CheckboxField,
  ListField,
  ListPage,
  useDialog
} from '@applications-terrains/birdz-react-library';
import { Grid, IconButton } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import SupportForm from './SupportForm';
import { useFetchContext } from '../../../../contexts/fetchContext';
import axios from 'axios';
import Delete from '@mui/icons-material/Delete';
import ModalWithLoader, { RequestStatus } from '../../../Tools/ModalWithLoader/ModalWithLoader';
import SupportSourceSelector from './SupportSourceSelector';

export type Support = {
  client: number | string;
  id: number | string;
  name: string | null;
  email: string | null;
  phone: string | null;
  is_active: boolean;
  main_contact: boolean;
  password: string | null;
  user_id: number | null;
};

export type SupportId = string | number | null | 'add';

const SupportsList = () => {
  const { id } = useParams();
  const endpoint = `/api/boi/breader/supports/`;
  const { needToFetch, toggleNeedToFetch } = useFetchContext();
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(null);

  const [supportId, setSupportId] = useState<SupportId>(null);
  const [newSupportSource, setNewSupportSource] = useState<'users' | 'new'>('new');

  const listFields: ListField[] = [
    {
      name: 'name',
      label: 'Nom'
    },
    {
      name: 'phone',
      label: 'Téléphone'
    },
    {
      name: 'email',
      label: 'Email'
    },
    {
      name: 'is_active',
      label: 'Actif / Inactif',
      options: {
        renderForm: () => <CheckboxField id="isActive" name="is_active" label="" />
      }
    },
    {
      name: 'main_contact',
      label: 'Contact Principal',
      options: {
        renderForm: () => <CheckboxField id="mainContact" name="main_contact" label="" />
      }
    }
  ];

  const actions = [
    {
      name: 'modifier',
      label: 'Modifier le gestionnaire',
      render: (support: Support) => {
        return (
          <IconButton onClick={() => setSupportId(support.id)}>
            <Edit fontSize="small" color="action" />
          </IconButton>
        );
      }
    },
    {
      name: 'delete-support',
      label: 'Supprimer le gestionnaire',
      render: (support: Support) => {
        return (
          <IconButton
            onClick={() => {
              confirmDialog({
                title: 'Supprimer le gestionnaire',
                content: 'Êtes-vous sûr de vouloir supprimer ce gestionnaire ?',
                onValidate: () => {
                  setRequestStatus('pending');
                  axios
                    .delete(`${endpoint}${support.id}`)
                    .then(() => {
                      setRequestStatus('success');
                    })
                    .catch(() => {
                      setRequestStatus('error');
                    });
                },
                onCancel: () => {
                  closeDialog();
                }
              });
            }}
          >
            <Delete fontSize="small" color="action" />
          </IconButton>
        );
      }
    }
  ];

  const handleCloseModalWithLoader = () => {
    closeDialog();
    setRequestStatus(null);
    toggleNeedToFetch(true);
  };
  return (
    <AppPaper sx={{ mt: 4 }}>
      <Grid justifyContent="space-between" container>
        <Grid item>
          <BirdzTitle>Gestionnaires</BirdzTitle>
        </Grid>
        <Grid item>
          <SupportSourceSelector
            name="gestionnaire"
            setNewSupportSource={setNewSupportSource}
            newSupportSource={newSupportSource}
            setSupportId={setSupportId}
          />
        </Grid>
      </Grid>
      {!needToFetch && (
        <ListPage
          endpoint={endpoint}
          fields={listFields}
          filters={`&client=${id}`}
          actions={actions}
        />
      )}
      {supportId !== null && (
        <SupportForm
          name="support"
          supportId={supportId}
          setSupportId={setSupportId}
          newSupportSource={newSupportSource}
        />
      )}
      <BirdzDialog options={dialogOptions} />
      <ModalWithLoader
        openModal={requestStatus === 'pending'}
        onClose={() => handleCloseModalWithLoader()}
        onSuccess={() => handleCloseModalWithLoader()}
        action="Suppression du gestionnaire"
        status={requestStatus}
        setStatus={setRequestStatus}
      />
    </AppPaper>
  );
};

export default SupportsList;

export type TourDevice = {
  ATTR_NUM_RUE_ABO?: string;
  COMMUNE_ABO: string;
  COMPTEUR?: string;
  COORD_X: string;
  COORD_Y: string;
  NUM_RUE_ABO: string;
  PDS?: string;
  RUE_ABO: string;
  MODULE?: string;
  device_id?: string;
  id?: string;
  device_type: string;
  frame_type?: number[];
  frame_type_data?: { label: string; id: number }[];
  reading_state?: 'PARTIALLY' | 'COLLECTED' | 'TOCOLLECT' | 'FAILURE';
  results?: {
    rssi: number;
    collected_frames: CollectedFrameType;
  } | null;
  data?: TourDevice;
};

export type Tour = {
  name: string;
  patrimonyStep: TourDevice[] | null;
  devicesStep: TourDevice[] | null;
  readerStep: { value: number; label: string } | null;
  model?: 'tour_devices' | 'tour_devices_filtered' | 'tour_devices_selected';
  client?: number | null;
};

export type StepType = 'name' | 'patrimonyStep' | 'devicesStep' | 'readerStep' | null;

export type CollectedFrameType =
  | {
      datetime: number | string;
      frame_type_value: number;
    }[]
  | null;

export type TourDeviceProps = {
  data: TourDevice;
  device_id: string;
  device_type: string;
  frame_type: number[];
  frame_type_data?: { label: string; id: number }[];
  id: number;
  reading_state: 'PARTIALLY' | 'COLLECTED' | 'TOCOLLECT' | 'FAILURE';
  results: {
    rssi: number;
    collected_frames: CollectedFrameType;
  } | null;
};

export type TourDetail = {
  id: number;
  created_at: number;
  name: string;
  reader: number;
  reader_name: string;
  statistics: any;
  tour_devices: TourDeviceProps[];
  updated_at: number;
  tour_route: {
    tour_route: TourRoute[];
  };
};

export type TourRoute = { datetime: string; latitude: number; longitude: number };
export type Target = 'all' | 'selected';

export const removeDuplicatesByModule = (array: TourDevice[]) => {
  return [...new Map(array.map((item) => [item.MODULE, item])).values()];
};

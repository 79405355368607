import React, { useEffect, useState } from 'react';
import { BirdzDialog, useDialog, StyledTitle } from '@applications-terrains/birdz-react-library';
import axios from 'axios';
import { Button, Grid, IconButton } from '@mui/material';
import { BirdzNotif, useNotif } from '@applications-terrains/birdz-react-library';
import moment from 'moment';
import ErrorPopover from './ErrorPopover';
import CheckIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { PiFileCsv } from 'react-icons/pi';
import Search from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PagePaper from '../../../Tools/Pages/PagePaper';
import BSearchForm from '../../../Tools/Search/BSearchForm';
import BTable from '../../../Tools/Table/BTable';
import ModalWithLoader, { RequestStatus } from '../../../Tools/ModalWithLoader/ModalWithLoader';
import CancelIcon from '@mui/icons-material/Cancel';
import Delete from '@mui/icons-material/Delete';
import { useFetchContext } from '../../../../contexts/fetchContext';

const ToursList = () => {
  const endpoint = '/api/boi/breader/tours/';
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const { notif, notifOptions } = useNotif();
  const [cities, setCities] = useState<{ label: string; value: string }[] | undefined>(undefined);

  const [readers, setReaders] = useState<{ label: string; value: number }[] | undefined>(undefined);
  const [frameTypes, setFrameTypes] = useState<{ label: string; value: number }[] | undefined>(
    undefined
  );
  const [filterString, setFilterString] = useState<string>('');
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(null);
  const [refresh, setRefresh] = useState(false);
  const fetchContext = useFetchContext();
  const model = 'breader-tours';

  useEffect(() => {
    refresh && setRefresh(false);
  }, [refresh]);

  const fetchCities = async () => {
    await axios.get('/api/boi/breader/tours/cities/').then((response) => {
      const data = response.data;
      const cities = data.map((city: string) => {
        return { value: city, label: city };
      });
      setCities(cities);
    });
  };
  const fetchReaders = async () => {
    await axios.get('/api/boi/breader/tours/readers/').then((response) => {
      const data = response.data;
      const readers = data.map((reader: { id: number; name: string }) => {
        return { value: reader.id, label: reader.name };
      });
      setReaders(readers);
    });
  };

  const fetchFrameTypes = async () => {
    type Frame_type = { id: number; label: string };
    await axios.get('/api/boi/breader/frame-types/').then((response) => {
      const data: Frame_type[] = response.data.results;
      const types = data.map((type) => {
        return { value: type.id, label: type.label };
      });
      const typesSorted = types.sort((a, b) => a.label.localeCompare(b.label));
      setFrameTypes(typesSorted);
    });
  };

  useEffect(() => {
    !cities && fetchCities();
    !readers && fetchReaders();
    !frameTypes && fetchFrameTypes();
  }, [cities, readers, frameTypes]);

  const searchFields = [
    {
      name: 'created_at_after',
      id: 'search_by_date_after',
      label: 'Entre le ',
      type: 'datepicker'
    },
    {
      name: 'created_at_before',
      id: 'search_by_date_before',
      label: 'Et le',
      type: 'datepicker'
    },
    {
      name: 'client_name',
      label: 'Client',
      options: {
        identifier: 'name',
        source: '/api/boi/breader/clients/',
        label: 'name',
        searchIsMulti: false
      }
    },
    {
      name: 'name',
      label: 'Nom',
      options: {
        identifier: 'name',
        source: endpoint,
        label: 'name',
        searchIsMulti: false
      }
    },
    {
      name: 'city',
      label: 'Commune',
      type: 'select',
      options: {
        values: cities
      }
    },
    {
      name: 'reader',
      label: 'Releveur',
      type: 'select',
      options: {
        values: readers
      }
    },
    {
      name: 'frame_type',
      id: 'search_by_type',
      label: 'Type',
      type: 'select-multiple',
      options: {
        values: frameTypes
      }
    }
  ];

  const listFields = [
    {
      name: 'client_name',
      label: 'Client'
    },
    { name: 'name', label: 'Nom' },
    {
      name: 'created_at',
      label: 'Date de création',
      transform: (value: any) => {
        return value ? moment.unix(value).format('DD/MM/YYYY à HH:mm:ss') : '-';
      }
    },
    { name: 'statistics', label: 'Relevé / Nb total - %' },
    { name: 'reader_name', label: 'Releveur' },
    {
      name: 'closing_date',
      label: 'Retour / Export',
      transform: (value: any) => {
        return value ? moment.utc(value).format('DD/MM/YYYY à HH:mm:ss') : '-';
      }
    },
    {
      name: 'exported',
      label: 'Etat',
      transform: (value: boolean | null, row: { error_details: string | null }) => {
        return (
          <>
            <span className={`d-flex align-items-center`}>
              {value === true ? 'OK' : 'Erreur'}
              {value === true ? (
                <CheckIcon className="ml-25" color="success" />
              ) : (
                <ErrorIcon className="ml-25" color="error" />
              )}
            </span>
            {!value && row.error_details && <ErrorPopover errorDetails={row.error_details} />}
          </>
        );
      }
    },
    {
      name: 'canceled',
      label: 'Statut',
      transform: (value: any) => {
        return value && value === true ? 'Annulée' : 'Valide';
      }
    }
  ];

  const actions = [
    {
      name: 'details',
      label: 'Voir les détails',
      render: (tour: { id: number }) => {
        return (
          <IconButton component={Link} to={`/boi/breader/tours/details/${tour.id}`}>
            <Search fontSize="small" />
          </IconButton>
        );
      }
    },
    {
      name: 'cancel-tour',
      label: 'Annuler la tournée',
      render: (model: { id: number; canceled: boolean }) => {
        if (!model.canceled) {
          return (
            <IconButton
              onClick={() => {
                confirmDialog({
                  title: 'Annuler la tournée',
                  content: 'Êtes-vous sûr de vouloir annuler cette tournée ?',
                  onValidate: () => {
                    axios.post(`${endpoint}${model.id}/cancel/`).then(
                      () => {
                        setRefresh(true);
                        notif({
                          content: 'La tournée a été annulée avec succès',
                          type: 'success'
                        });
                        fetchContext.toggleNeedToFetch(true);
                      },
                      () => {
                        notif({
                          content: "Une erreur est survenue lors de l'annulation",
                          type: 'error'
                        });
                      }
                    );
                    closeDialog();
                  },
                  onCancel: () => {
                    closeDialog();
                  }
                });
              }}
            >
              <CancelIcon fontSize="small" />
            </IconButton>
          );
        }
        return null;
      }
    },
    {
      name: 'delete-tour',
      label: 'Supprimer la tournée',
      render: (model: { id: number }) => {
        return (
          <IconButton
            onClick={() => {
              confirmDialog({
                title: 'Supprimer la tournée',
                content: 'Êtes-vous sûr de vouloir supprimer cette tournée ?',
                onValidate: () => {
                  setRequestStatus('pending');
                  axios.delete(`${endpoint}${model.id}/`).then(
                    () => {
                      setRefresh(true);
                      notif({
                        content: 'La tournée a été supprimée avec succès',
                        type: 'success'
                      });
                      fetchContext.toggleNeedToFetch(true);
                      setRequestStatus('success');
                    },
                    () => {
                      setRequestStatus('error');
                      notif({
                        content: 'Une erreur est survenue lors de la suppression',
                        type: 'error'
                      });
                    }
                  );
                  closeDialog();
                },
                onCancel: () => {
                  closeDialog();
                  setRequestStatus(null);
                }
              });
            }}
          >
            <Delete fontSize="small" />
          </IconButton>
        );
      }
    }
  ];

  return (
    <PagePaper
      title={
        <Grid justifyContent="space-between" alignItems={'center'} container>
          <Grid item>
            <StyledTitle className="m-0">Liste des tournées</StyledTitle>
          </Grid>
          <Grid item>
            <Link to={'/boi/breader/tours/add'}>
              <Button variant="contained">
                <AddCircleIcon sx={{ mr: 1 }} /> Ajouter une tournée
              </Button>
            </Link>
          </Grid>
        </Grid>
      }
    >
      <>
        <BSearchForm
          columns={2}
          fields={searchFields}
          setSearchString={(filterString) => {
            setFilterString(filterString);
          }}
        />
        {!fetchContext.needToFetch && (
          <BTable
            model={model}
            endpoint={endpoint + filterString.replace('&', '?')}
            fields={listFields}
            actions={actions}
            exportButtons={[
              {
                id: 'export-tour-button',
                name: 'Exporter en csv',
                fileName: 'tours',
                type: 'csv',
                icon: <PiFileCsv style={{ marginRight: '0.5rem' }} />,
                requestEndpoint: '/api/boi/breader/tours/export-listing/',
                keepQueryParams: false,
                onSuccess: () => {
                  notif({
                    type: 'success',
                    content: 'Export réussi'
                  });
                }
              }
            ]}
          />
        )}
      </>
      <BirdzDialog options={dialogOptions} />
      <BirdzNotif options={notifOptions} />

      <ModalWithLoader
        openModal={requestStatus === 'pending'}
        onClose={() => {
          closeDialog();
          setRequestStatus(null);
        }}
        action="Suppression de la tournée"
        status={requestStatus}
        setStatus={setRequestStatus}
      />
    </PagePaper>
  );
};

export default ToursList;

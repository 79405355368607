import React, { useEffect, useState } from 'react';
import { Target, TourDetail, TourDevice } from '../types';
import { useFetchDataRefsContext } from '../../../../../../contexts/FetchDataRefsContext';
import { Backdrop, Box, Button, Dialog, DialogContent, DialogTitle, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useTourFormContext } from '../../../../context/tourFormContext';
import { BirdzTitle } from '@applications-terrains/birdz-react-library';
import TourForm from '../../../../TourForm';

const HandleCreateTour = ({ tour }: { tour: TourDetail }) => {
  const [open, setOpen] = useState(false);
  const { dataRefs } = useFetchDataRefsContext();
  const { setTourData, setCurrentStep } = useTourFormContext();
  const [target, setTarget] = useState<Target>('all');

  useEffect(() => {
    setTarget(
      (dataRefs['tour_devices_selected'] && dataRefs['tour_devices_selected'].results.length > 0) ||
        (dataRefs['tour_devices_filtered'] && dataRefs['tour_devices_filtered'].results.length > 0)
        ? 'selected'
        : 'all'
    );
  }, [dataRefs]);

  useEffect(() => {
    open && setCurrentStep('devicesStep');
    const devices =
      target === 'all'
        ? dataRefs['tour_devices']?.results
        : dataRefs['tour_devices_selected']?.results &&
            dataRefs['tour_devices_selected']?.results?.length > 0
          ? dataRefs['tour_devices_selected']?.results
          : dataRefs['tour_devices_filtered']?.results;

    setTourData(
      open
        ? {
            name: tour.name + ' copie',
            patrimonyStep: devices as TourDevice[],
            devicesStep: null,
            readerStep: { label: tour.reader_name, value: tour.reader },
            model:
              target === 'all'
                ? 'tour_devices'
                : dataRefs['tour_devices_selected']?.results &&
                    dataRefs['tour_devices_selected']?.results?.length > 0
                  ? 'tour_devices_selected'
                  : 'tour_devices_filtered'
          }
        : {
            name: '',
            patrimonyStep: null,
            devicesStep: null,
            readerStep: null,
            model: undefined
          }
    );
  }, [open, target, dataRefs]);

  const labels = {
    base: 'Créer une nouvelle tournée',
    all: 'avec tous les équipement à collecter',
    selected: 'avec les équipements sélectionnés uniquement'
  };

  return (
    <Box textAlign="right">
      <CreateTourButton
        setOpen={setOpen}
        open={open}
        labels={labels}
        target={target}
        setTarget={setTarget}
      />
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <BirdzTitle className="m-0">{`Créer une nouvelle tournée ${labels[target]}`}</BirdzTitle>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <TourForm role="boe" onCancel={() => setOpen(false)} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

const CreateTourButton = ({
  setOpen,
  open,
  labels,
  target,
  setTarget
}: {
  setOpen: (open: boolean) => void;
  open: boolean;
  labels: Record<string, string>;
  target: Target;
  setTarget: (target: Target) => void;
}) => {
  const { dataRefs } = useFetchDataRefsContext();
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <Button variant="contained" onClick={() => setOpen(!open)} size="small">
      {(dataRefs['tour_devices_selected'] &&
        dataRefs['tour_devices_selected'].results.length > 0) ||
      (dataRefs['tour_devices_filtered'] &&
        dataRefs['tour_devices_filtered'].results.length > 0) ? (
        <Box className="d-flex" sx={{ whiteSpace: 'nowrap', flexWrap: 'nowrap' }}>
          <Box>{labels['base']}</Box>
          <Box
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setExpanded(!expanded);
            }}
            sx={{
              ml: 0.5,
              pl: 1,
              pr: 0.5,
              backgroundColor: 'rgba(255, 255, 255, 0.35)',
              borderRadius: '14px',
              height: 24
            }}
            className="d-flex align-items-center"
          >
            {labels[target]}
            <ExpandMoreIcon
              sx={{
                ml: 0.5,
                transition: 'transform 0.3s ease', // Animation pour la rotation
                transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)' // Rotation de l'icône
              }}
            />
          </Box>
          {
            <Backdrop
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                cursor: 'default'
              }}
              open={expanded}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setExpanded(false);
              }}
            />
          }
          {
            <Box
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setTarget(target === 'all' ? 'selected' : 'all');
                setExpanded(false);
              }}
              sx={{
                px: 1,
                backgroundColor: '#9AC6DB',
                borderRadius: '14px',
                display: 'flex',
                opacity: expanded ? 1 : 0,
                pointerEvents: expanded ? 'auto' : 'none',
                transform: expanded ? 'translateY(0)' : 'translateY(30px)', // Déplacer l'élément verticalement
                position: 'absolute',
                transition: 'transform 0.3s ease, opacity 0.3s ease', // Animation pour la translation et l'opacité
                top: -30, // L'élément restera à `top: 0` mais se déplacera en Y
                right: 0,
                height: 24
              }}
              className="d-flex align-items-center"
            >
              {labels[target === 'all' ? 'selected' : 'all']}
              {target === 'selected' ? (
                <FilterAltOffIcon sx={{ ml: 0.5 }} fontSize="small" />
              ) : (
                <FilterAltIcon sx={{ ml: 0.5 }} fontSize="small" />
              )}
            </Box>
          }
        </Box>
      ) : (
        <Box className="d-flex" gap={0.5} sx={{ whiteSpace: 'nowrap', flexWrap: 'nowrap' }}>
          <Box>{labels['base']}</Box>
          <Box>{labels['all']}</Box>
        </Box>
      )}
    </Button>
  );
};

export default HandleCreateTour;

import React, { useState } from 'react';
import { AppPaper, ListPage } from '@applications-terrains/birdz-react-library';
import Select from 'react-select';
import { Box } from '@mui/material';
import SetFrameTypes from './SetFrameTypes';
import { TourDevice, TourDeviceProps } from '../types';
import { useTourFormContext } from '../../../../context/tourFormContext';
import { removeDuplicatesByProperties, sortDevices } from './tourUtils';
import { deviceStatuses } from '../TourMap/TourMapUtils';
import ReadingState from '../TourDetails/ReadingState';
import { useParams } from 'react-router-dom';
import { StepsProps } from '../../../../types';

const TourListPage = ({ role }: StepsProps) => {
  const { id } = useParams();
  const { tourData, selectedDevices, setSelectedDevices, refreshListPage, setShowFrameTypeModal } =
    useTourFormContext();

  const devices = (tourData.patrimonyStep || []).map((device) =>
    'data' in device
      ? {
          ...device,
          ...device.data,
          MODULE: device.MODULE || device.device_id,
          device_id: device.MODULE || device.device_id
        }
      : device
  );

  const [filters, setFilters] = useState<Partial<{ device_type: string; reading_state: string }>>(
    {}
  );

  if (!devices) return null;

  const devicesToDisplay = devices.filter((device) =>
    Object.entries(filters).every(([key, value]) =>
      value ? device[key as keyof typeof device] === value : true
    )
  );

  const deviceTypes = [...new Set(tourData?.patrimonyStep?.map((device) => device.device_type))];

  const listFields = [
    { name: 'MODULE', label: 'Module' },
    {
      name: '',
      label: 'Adresse',
      transform: (_: any, value: TourDevice) => {
        return (
          `${value?.NUM_RUE_ABO || '-'} ${value?.ATTR_NUM_RUE_ABO || ''} ${value?.RUE_ABO || '-'} ${value?.COMMUNE_ABO || '-'}` ||
          '-'
        );
      }
    },
    {
      name: 'reading_state',
      label: 'Statut',
      transform: (value: TourDeviceProps['reading_state']) => {
        return value in deviceStatuses ? <ReadingState value={value} /> : '-';
      }
    },
    { name: 'device_type', label: 'Type', options: { width: '50px' } },
    {
      name: 'frame_type',
      label: 'Type de relevé',
      transform: (_: any, device: TourDevice) => {
        return device.frame_type_data?.map((el) => el.label).join(',') || '-';
      }
    }
  ];

  return (
    <AppPaper sx={{ p: 2, mt: 2 }}>
      <Box className="d-flex align-items-center" gap={1} sx={{ mt: 2 }}>
        {'Filtrer le tableau par'}
        {deviceTypes.length > 1 && (
          <Select
            isClearable
            options={deviceTypes.map((type) => ({
              label: type,
              value: type
            }))}
            onChange={(type) => setFilters({ ...filters, device_type: type?.value || '' })}
            placeholder="type"
          />
        )}
        {id && (
          <Select
            isClearable
            options={Object.entries(deviceStatuses)
              .filter(([key]) => key !== 'default')
              .map(([key, value]) => ({
                label: value,
                value: key
              }))}
            onChange={(status) => setFilters({ ...filters, reading_state: status?.value || '' })}
            placeholder="statut"
          />
        )}
      </Box>

      <Box sx={{ position: 'relative' }}>
        {devicesToDisplay.length > 0 && (
          <Box
            sx={{
              position: 'absolute',
              width: '52px',
              height: '30px',
              marginTop: '50px',
              cursor: 'pointer',
              backgroundColor: 'rgb(222, 226, 230)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            onClick={() => {
              if (
                selectedDevices.length === 0 ||
                (selectedDevices.length > 0 && selectedDevices.length < devicesToDisplay.length)
              ) {
                setSelectedDevices(devicesToDisplay);
                setShowFrameTypeModal(true);
              } else {
                setSelectedDevices([]);
                setShowFrameTypeModal(false);
              }
            }}
          >
            <input
              type="checkbox"
              checked={selectedDevices.length === devicesToDisplay.length}
              readOnly
              style={{ cursor: 'pointer' }}
            />
          </Box> //override select All checkbox
        )}
        <ListPage
          key={JSON.stringify(filters)}
          loadedData={refreshListPage ? [] : sortDevices(devicesToDisplay)}
          fields={listFields}
          onSelectItems={(devices) => {
            const filtered = removeDuplicatesByProperties(devices as TourDevice[]);
            setSelectedDevices(filtered as TourDevice[]);
          }}
          selectedItems={selectedDevices}
        />
        <Box className="d-flex justify-content-end w-100" sx={{ mt: 2 }}>
          <SetFrameTypes
            devices={selectedDevices}
            setSelectedDevices={setSelectedDevices}
            listFields={listFields.filter((field) => field.name !== 'frame_type')}
            role={role}
          />
        </Box>
      </Box>
    </AppPaper>
  );
};

export default TourListPage;

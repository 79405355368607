import React from 'react';
import PagePaper from '../Pages/PagePaper';
import { Typography } from '@mui/material';
import CrStatisticsPie from '../../CR/Statistics/CrStatisticsPie';
import EmptyPageWithLoader from '../Pages/EmptyPageWIthLoader';

export type PieChartProps = {
  label: string;
  data: {
    name: string;
    value: number;
  }[];
  displayMode: 'values' | 'percent';
  loading: boolean;
};

const PieChartWrapper = ({ label, data, displayMode, loading }: PieChartProps) => {
  return (
    <PagePaper
      bordered
      title={
        <Typography variant="overline" sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          {label}
        </Typography>
      }
    >
      {loading ? (
        <EmptyPageWithLoader height={300} />
      ) : (
        <CrStatisticsPie
          data={data}
          displayMode={displayMode}
          verticalAlign="middle"
          align="right"
        />
      )}
    </PagePaper>
  );
};

export default PieChartWrapper;

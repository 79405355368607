import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import './DashboardLayout.scss';
import moment from 'moment';
import { Box, Button, Tooltip } from '@mui/material';
import routes from '../../routes';
import { Breadcrumbs } from '@applications-terrains/birdz-react-library';
import { authService } from '../..';
import { GlobalContextProvider, useGlobalContext } from '../../contexts/globalContext';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export type RouteProps = {
  path: string;
  element?: any;
  label?: string;
  permissions?: string[];
  children?: RouteProps[];
};

const Dashboard = ({ component, ...props }: any) => {
  const { ...rest } = props;
  const { theme, sidebarIsExpanded } = useGlobalContext();

  return (
    <div
      className={`layout-wrapper ${theme} ${authService.BOIorBOE('boi', 'boe')} ${authService.canAccessBReaderBOE() ? 'boe' : ''}`}
    >
      <Sidebar {...rest} />

      <Box
        className={`main-panel ${!sidebarIsExpanded ? 'expand' : ''}`}
        sx={{ px: 2, boxSizing: 'border-box' }}
      >
        <Box
          className="d-flex align-items-center justify-content-between"
          sx={{ borderBottom: '1px solid', borderColor: 'divider', height: '50px', mb: 2 }}
        >
          <Box className="d-flex align-items-center">
            <GoBackButton />
            <Breadcrumbs routes={routes} />
          </Box>
          <Tooltip
            placement="left"
            title={
              authService.user?.previous_login ? (
                <span className="last-login">
                  Dernière connexion le{' '}
                  {moment(authService.user.previous_login).format('DD/MM/YYYY à HH:mm:ss')}
                </span>
              ) : (
                ''
              )
            }
          >
            <Box className="navbar">
              {authService.user ? authService.user.name : ''}
              <Box
                className="d-flex align-items-center justify-content-center avatar-container"
                sx={{
                  ml: 1
                }}
              >
                {authService.user?.avatar ? (
                  <div
                    style={{
                      backgroundImage: 'url("' + authService.user.avatar + '"',
                      backgroundPosition: 'center',
                      height: '30px',
                      width: '30px',
                      backgroundSize: 'cover'
                    }}
                  />
                ) : (
                  <AccountCircleRoundedIcon fontSize="large" />
                )}
              </Box>
            </Box>
          </Tooltip>
        </Box>

        <Box className="page-content">
          <Box className="inner h-100 d-flex flex-column">{component}</Box>
        </Box>
      </Box>
    </div>
  );
};

const GoBackButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    setIsVisible(/settings|create|add|view|detail|details|edit/.test(pathname));
  }, [pathname]);

  return (
    <Box className={`animated-button ${!isVisible ? 'exit' : ''}`} sx={{ mr: isVisible ? 1 : 0 }}>
      <Button
        sx={{
          px: 0,
          border: '1px solid',
          borderColor: 'divider',
          color: 'divider',
          width: '35px',
          maxWidth: '35px',
          minWidth: '35px',
          height: '35px'
        }}
        onClick={() => navigate(-1)}
      >
        <ArrowBackIcon />
      </Button>
    </Box>
  );
};

const DashboardLayout = ({ component, ...props }: any) => {
  return (
    <GlobalContextProvider>
      <Dashboard component={component} {...props} />
    </GlobalContextProvider>
  );
};

export default DashboardLayout;

import React, { useState } from 'react';
import { BirdzDialog, useDialog, BirdzTitle } from '@applications-terrains/birdz-react-library';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Button, Grid, IconButton } from '@mui/material';
import Delete from '@mui/icons-material/Delete';
import { useFetchContext } from '../../../contexts/fetchContext';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Edit from '@mui/icons-material/Edit';
import moment from 'moment';
import ModalWithLoader, { RequestStatus } from '../../Tools/ModalWithLoader/ModalWithLoader';
import PagePaper from '../../Tools/Pages/PagePaper';
import BSearchForm from '../../Tools/Search/BSearchForm';
import BTable from '../../Tools/Table/BTable';

const BReaderClientsList = () => {
  const endpoint = '/api/boi/breader/clients/';
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(null);
  const [filterString, setFilterString] = useState<string>('');

  const { needToFetch, toggleNeedToFetch } = useFetchContext();

  const listFields = [
    {
      name: 'name',
      label: 'Nom'
    },
    {
      name: 'created_at',
      label: 'Date de création',
      transform: (value: any) => {
        return value ? moment.unix(value).format('DD/MM/YYYY à HH:mm:ss') : '-';
      }
    },
    {
      name: 'readers_count',
      label: 'Nb de releveurs'
    }
  ];

  const actions = [
    {
      name: 'details',
      label: 'Voir les détails',
      render: (client: { id: number }) => {
        return (
          <IconButton component={Link} to={`/boi/breader/admin/clients/edit/${client.id}`}>
            <Edit fontSize="small" />
          </IconButton>
        );
      }
    },
    {
      name: 'delete-client',
      label: 'Supprimer le client',
      render: (client: { id: number }) => {
        return (
          <IconButton
            onClick={() => {
              confirmDialog({
                title: 'Supprimer le client',
                content: 'Êtes-vous sûr de vouloir supprimer ce client ?',
                onValidate: () => {
                  setRequestStatus('pending');
                  axios
                    .delete(`${endpoint}${client.id}`)
                    .then(() => {
                      setRequestStatus('success');
                    })
                    .catch(() => {
                      setRequestStatus('error');
                    });
                },
                onCancel: () => {
                  closeDialog();
                }
              });
            }}
          >
            <Delete fontSize="small" color="action" />
          </IconButton>
        );
      }
    }
  ];

  const searchFields = [
    {
      name: 'name',
      label: 'Nom',
      options: {
        identifier: 'name',
        source: endpoint,
        label: 'name',
        searchIsMulti: false
      }
    },
    {
      name: 'reader_name',
      label: 'Nom du releveur',
      options: {
        identifier: 'name',
        source: '/api/boi/breader/tours/readers',
        label: 'name',
        searchIsMulti: false
      }
    }
  ];

  const handleCloseModalWithLoader = () => {
    closeDialog();
    setRequestStatus(null);
    toggleNeedToFetch(true);
  };

  return (
    <PagePaper
      title={
        <Grid justifyContent="space-between" alignItems={'center'} container>
          <Grid item>
            <BirdzTitle className="m-0">Clients B-Reader</BirdzTitle>
          </Grid>
          <Grid item>
            <Link to="/boi/breader/admin/clients/add">
              <Button variant="contained">
                <AddCircleIcon sx={{ mr: 1 }} /> Ajouter un client
              </Button>
            </Link>
          </Grid>
        </Grid>
      }
    >
      <>
        <BSearchForm
          columns={2}
          fields={searchFields}
          setSearchString={(filterString) => {
            setFilterString(filterString);
          }}
        />
        <BTable
          model={'breader-clients'}
          endpoint={endpoint + filterString.replace('&', '?')}
          fields={listFields}
          actions={actions}
          refresh={needToFetch}
        />
      </>
      <BirdzDialog options={dialogOptions} />
      <ModalWithLoader
        openModal={requestStatus === 'pending'}
        onClose={() => handleCloseModalWithLoader()}
        onSuccess={() => handleCloseModalWithLoader()}
        action="Suppression du client"
        status={requestStatus}
        setStatus={setRequestStatus}
      />
    </PagePaper>
  );
};

export default BReaderClientsList;

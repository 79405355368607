import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import useReinitializeForm from '../../hooks/useReinitializeForm';

// Définition de OptionType avec un type générique T
type OptionType<T> = {
  label: string;
  value: T;
};

// Définition de Value en fonction de IsMulti et du type T de OptionType
type Value<T, IsMulti extends boolean> = IsMulti extends true
  ? OptionType<T>[] | null
  : OptionType<T> | null;

// Définition des props avec inférence automatique de T
type ClearableSelectProps<T, IsMulti extends boolean> = {
  options: OptionType<T>[];
  onChange: (value: Value<T, IsMulti>) => void;
  disabled?: boolean;
  defaultValue?: Value<T, IsMulti>;
  isLoading?: boolean;
  placeholder?: string;
  isMulti?: IsMulti;
};

// Composant générique avec inférence automatique de T
function ClearableSelect<T, IsMulti extends boolean = false>({
  options,
  onChange,
  disabled,
  defaultValue,
  isLoading,
  placeholder,
  isMulti
}: ClearableSelectProps<T, IsMulti>) {
  const [value, setValue] = useState<Value<T, IsMulti>>(defaultValue || null);
  const reset = useReinitializeForm();

  useEffect(() => {
    if (reset) {
      setValue(null);
      onChange(null);
    }
  }, [reset]);

  useEffect(() => {
    if (Array.isArray(value)) {
      const hasAllCities = value.some((item) => item.value === 'allCities');
      if (hasAllCities && value.length > 1) {
        setValue([{ label: 'Toutes', value: 'allCities' } as OptionType<T>] as Value<T, IsMulti>);
      }
    }
  }, [value]);

  return (
    <Select<OptionType<T>, IsMulti>
      options={options}
      onChange={(value) => {
        setValue(value as Value<T, IsMulti>);
        onChange(value as Value<T, IsMulti>);
      }}
      value={value}
      isMulti={isMulti}
      isLoading={isLoading}
      placeholder={placeholder}
      isClearable
      isDisabled={disabled}
    />
  );
}

export default ClearableSelect;

import React from 'react';
import {
  AppPaper,
  BirdzTitle,
  ListPage,
  BirdzDialog,
  useDialog,
  FormField,
  Item
} from '@applications-terrains/birdz-react-library';
import moment from 'moment';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useNotif } from '@applications-terrains/birdz-react-library';
import { BirdzNotif } from '@applications-terrains/birdz-react-library';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

const Sections = (params: any) => {
  const endpoint = '/api/boi/administration/sections/';
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const { notif, notifOptions } = useNotif();

  const formFields: FormField[] = [
    {
      name: 'code',
      type: 'text',
      label: 'Code section *'
    },
    {
      name: 'description',
      type: 'text',
      label: 'Description'
    },
    {
      name: 'is_active',
      type: 'radio',
      label: 'Etat',
      options: {
        values: [
          { value: true, label: 'Actif' },
          { value: false, label: 'Inactif' }
        ]
      },
      defaultValue: true,
      permissions: ['IS_ACTIVE']
    },
    {
      name: 'updated_at',
      type: 'readonly',
      label: 'Dernière modification',
      transform: (value: any) => {
        const date = value && moment.unix(value).format('DD/MM/YYYY à HH:mm:ss');
        return date || '';
      }
    }
  ];
  const listFields = [
    { name: 'description', label: 'Description' },
    {
      name: 'code',
      label: 'Code section',
      options: { width: '180px' },
      orderable: true
    },
    {
      name: 'is_active',
      label: 'Actif',
      className: 'text-center',
      options: { width: '130px' },
      orderable: true
    },
    {
      name: 'updated_at',
      label: 'Date de modification',
      options: { width: '240px' },
      orderable: true,
      transform: (value: any) => {
        return (value && moment.unix(value).format('DD/MM/YYYY à HH:mm:ss')) || '';
      }
    }
  ];
  const searchFields = [
    {
      name: 'description',
      id: 'search_by_description',
      label: 'Description',
      options: {
        identifier: 'description',
        source: endpoint,
        label: 'description',
        searchIsMulti: false
      }
    },
    {
      name: 'code',
      id: 'search_by_code',
      label: 'Code Section',
      options: {
        identifier: 'code',
        source: endpoint,
        label: 'code',
        searchIsMulti: false
      }
    },
    {
      name: 'group',
      id: 'search_by_group',
      label: 'Groupe',
      options: {
        source: '/api/boi/administration/groups/?is_active=true&',
        label: 'name',
        searchIsMulti: false
      }
    }
  ];
  const actions = [
    {
      name: 'edit',
      label: 'Modifier la section',
      render: (section: any) => {
        return (
          <IconButton component={Link} to={`/boi/administration/sections/edit/${section.id}`}>
            <EditIcon fontSize="small" />
          </IconButton>
        );
      }
    },
    {
      name: 'delete-section',
      label: 'Supprimer la section',
      render: (model: any) => {
        return (
          <IconButton
            onClick={() => {
              confirmDialog({
                title: 'Supprimer la section',
                content: 'Êtes-vous sûr de vouloir supprimer cette section?',
                onValidate: () => {
                  axios.delete(`${endpoint}${model.id}/`).then(
                    () => {
                      notif({
                        content: 'La section a été supprimée avec succès',
                        type: 'success'
                      });
                      document.location.reload();
                    },
                    () => {
                      notif({
                        content: 'Une erreur est survenue lors de la suppression',
                        type: 'error'
                      });
                    }
                  );
                  closeDialog();
                },
                onCancel: () => {
                  closeDialog();
                }
              });
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        );
      }
    }
  ];

  return (
    <AppPaper>
      {params.action === 'list' && (
        <>
          <Grid justifyContent="space-between" container>
            <Grid item>
              <BirdzTitle>Liste des sections</BirdzTitle>
            </Grid>
            <Grid item>
              <Link to="/boi/administration/sections/add">
                <Button variant="contained" sx={{ mt: 3 }}>
                  <AddCircleIcon sx={{ mr: 1 }} /> Ajouter une section
                </Button>
              </Link>
            </Grid>
          </Grid>

          <ListPage
            endpoint={endpoint}
            fields={listFields}
            actions={actions}
            searchFields={searchFields}
            defaultOrder={['code']}
          />
          <BirdzDialog options={dialogOptions} />
        </>
      )}
      {(params.action === 'add' || params.action === 'edit') && (
        <Item
          action={params.action}
          endpoint={endpoint}
          fields={formFields}
          name={`${params.action === 'add' ? 'Ajouter une' : 'Modifier la'} section`}
        />
      )}
      <BirdzNotif options={notifOptions} />
    </AppPaper>
  );
};

export default Sections;
